<template>
    <!-- Modal AdddeviceModel -->
    <div class="modal fade" id="adddeviceModelModal" tabindex="-1" aria-labelledby="adddeviceModelModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="adddeviceModelModalLabel">+ สร้างรายละเอียดอุปกรณ์</h5>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                </div>
                <div class="modal-body">
                    <div class="">
                        <div>
                            <span>ยี่ห้อ : </span><input v-model="addnewdeviceModel.banner" placeholder="ยี่ห้อ"
                                style="width: 100%; height: 35px;" />
                        </div>
                        <div>
                            <span>รุ่น : </span><input v-model="addnewdeviceModel.model" placeholder="รุ่น"
                                style="width: 100%; height: 35px;" />
                        </div>
                        <div class="mt-3">
                            <span>รายละเอียด : </span><input v-model="addnewdeviceModel.discription"
                                placeholder="รายละเอียด" style="width: 100%; height: 35px;" />
                        </div>
                        <div>
                            <span>หมายเหตุ : </span><input v-model="addnewdeviceModel.note" placeholder="หมายเหตุ"
                                style="width: 100%; height: 35px;" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button v-if="addnewdeviceModel.banner!='' && addnewdeviceModel.model!='' && addnewdeviceModel.discription!='' " type="button" class="btn btn-warning" @click="addhardwareByAdmin()" data-bs-dismiss="modal">+ สร้าง</button>
                    <button v-else type="button" class="btn btn-light" >
                        +กรุณากรอกข้อมูลให้ครบ</button>
                </div>
            </div>
        </div>
    </div>

      <!-- Modal EditdeviceModel -->
      <div class="modal fade" id="editdeviceModelModal" tabindex="-1" aria-labelledby="editdeviceModelModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editdeviceModelModalLabel">แก้ไขรายละเอียดอุปกรณ์</h5>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                </div>
                <div class="modal-body">
                    <div class="">
                        <!-- {{ CurrentItem }} -->
                        <div>
                            <span>ยี่ห้อ : </span><input v-model="CurrentItem.name" placeholder="ยี่ห้อ"
                                style="width: 100%; height: 35px;" />
                        </div>
                        <div>
                            <span>รุ่น : </span><input v-model="CurrentItem.model" placeholder="รุ่น"
                                style="width: 100%; height: 35px;" />
                        </div>
                        <div class="mt-3">
                            <span>รายละเอียด : </span><input v-model="addnewdeviceModel.discription"
                                placeholder="รายละเอียด" style="width: 100%; height: 35px;" />
                        </div>
                        <div>
                            <span>หมายเหตุ : </span><input v-model="addnewdeviceModel.note" placeholder="หมายเหตุ"
                                style="width: 100%; height: 35px;" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-warning" @click="editHardwareByAdmin()" data-bs-dismiss="modal">แก้ไข</button>
                </div>
            </div>
        </div>
    </div>

      <!-- Modal delete ?นกำส -->
  <div class="modal fade" id="removedeviceModal" tabindex="-1" aria-labelledby="removedeviceModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="removedeviceModalLabel">ลบผู้ใช้งาน</h5>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body">
          <div class="text-center">ยืนยันการลบ</div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-danger" 
            data-bs-dismiss="modal" @click="deleteHardwareByAdmin()">
            ลบ</button>
        </div>
      </div>
    </div>
  </div>

    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/supperadmin/landing">
        <img src="../../assets/logo.png" style="width: 250px;" alt="">
      </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <router-link to="/supperadmin/map" style="text-decoration: none;">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="#">อุปกรณ์บนแผนที่</a>
                        </li>
                    </router-link>
                    <router-link v-if="roleArea=='ทั้งหมด'" to="/supperadmin/area" style="text-decoration: none;">
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#">เขตพื้นที่</a>
                        </li>
                    </router-link>
                    <router-link to="/supperadmin/adduser" style="text-decoration: none;">
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#">ผู้ใช้งาน</a>
                        </li>
                    </router-link>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            อุปกรณ์
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li>
                                <router-link to="/supperadmin/detailnamebox" style="text-decoration: none;"><a
                                        class="dropdown-item" href="">รายละเอียดกล่อง</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/supperadmin/detaildevice" style="text-decoration: none;"><a
                                        class="dropdown-item" href="">รายละเอียดที่ตั้งอุปกรณ์</a></router-link>
                            </li>
                            <li>
                                <router-link to="/supperadmin/detailhardware" style="text-decoration: none;"><a
                                        class="dropdown-item text-warning" href="#">รายละเอียดรุ่นอุปกรณ์</a>
                                </router-link>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li>
                <router-link to="/supperadmin/statusdevice" style="text-decoration: none;"><a class="dropdown-item"
                    href="#">รายงานอุปกรณ์ออฟไลน์</a></router-link>
              </li>
                            <!-- <li><a class="dropdown-item" href="#">รายงานอุปกรณ์ออฟไลน์</a></li> -->
                        </ul>
                    </li>
                </ul>
                <form class="d-flex">
                    <div class="col-12 text-end">
                        <button class="btn btn-warning ms-2" type="button" data-bs-toggle="modal"
                            data-bs-target="#adddeviceModelModal">+ เพิ่มรายละเอียดรุ่นอุปกรณ์</button>
                    </div>
                </form>
            </div>
        </div>
    </nav>

    <body>
        <div style="height:100px;"></div>
        <table class="">

            <tr>
                <th>ยี่ห้อ</th>
                <th>รุ่น</th>
                <th>วันที่สร้าง</th>
                <th>แก้ไข</th>
            </tr>
            <tr v-for="(hardwareItem, i) in listHardware" :key="i">
                <td>{{ hardwareItem.name }}</td>
                <td>{{ hardwareItem.model }}</td>
                <td>{{ hardwareItem.createdAt }}</td>
                <td class="text-start"> <span>
                        <font-awesome-icon type="button" data-bs-toggle="modal" data-bs-target="#editdeviceModelModal"
                            style="color: skyblue;" icon="fa-solid fa-pen" @click="setCurrentItem(hardwareItem)"
                            /> </span> <span class="ms-3">
                        <font-awesome-icon type="button" data-bs-toggle="modal" data-bs-target="#removedeviceModal"
                            style="color: #FFBBAC;" icon="fa-solid fa-box-archive" @click="setCurrentItem(hardwareItem)"
                          /></span></td>

            </tr>


        </table>

    </body>

</template>
<script>
import checktokenAPI from "../connectBackend/checkTokenFirst";
    export default {
        data() {
            return {
                roleArea:'',
                CurrentItem:{
                    id:null,
                    name:'',
                    model:''
                },
                listHardware: [],
                addnewdeviceModel: {
                    banner: '',
                    discription: '',
                    note: '',
                    model: ''
                },
                errorDelete:false,
                message: '',
                selected: '',
                selected2: '',
                provinces: [{
                        "id": 1,
                        "name_th": "กรุงเทพมหานคร",
                        "name_en": "Bangkok",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 2,
                        "name_th": "สมุทรปราการ",
                        "name_en": "Samut Prakan",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 3,
                        "name_th": "นนทบุรี",
                        "name_en": "Nonthaburi",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 4,
                        "name_th": "ปทุมธานี",
                        "name_en": "Pathum Thani",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 5,
                        "name_th": "พระนครศรีอยุธยา",
                        "name_en": "Phra Nakhon Si Ayutthaya",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 6,
                        "name_th": "อ่างทอง",
                        "name_en": "Ang Thong",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 7,
                        "name_th": "ลพบุรี",
                        "name_en": "Loburi",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 8,
                        "name_th": "สิงห์บุรี",
                        "name_en": "Sing Buri",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 9,
                        "name_th": "ชัยนาท",
                        "name_en": "Chai Nat",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 10,
                        "name_th": "สระบุรี",
                        "name_en": "Saraburi",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 11,
                        "name_th": "ชลบุรี",
                        "name_en": "Chon Buri",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 12,
                        "name_th": "ระยอง",
                        "name_en": "Rayong",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 13,
                        "name_th": "จันทบุรี",
                        "name_en": "Chanthaburi",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 14,
                        "name_th": "ตราด",
                        "name_en": "Trat",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 15,
                        "name_th": "ฉะเชิงเทรา",
                        "name_en": "Chachoengsao",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 16,
                        "name_th": "ปราจีนบุรี",
                        "name_en": "Prachin Buri",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 17,
                        "name_th": "นครนายก",
                        "name_en": "Nakhon Nayok",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 18,
                        "name_th": "สระแก้ว",
                        "name_en": "Sa Kaeo",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 19,
                        "name_th": "นครราชสีมา",
                        "name_en": "Nakhon Ratchasima",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 20,
                        "name_th": "บุรีรัมย์",
                        "name_en": "Buri Ram",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 21,
                        "name_th": "สุรินทร์",
                        "name_en": "Surin",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 22,
                        "name_th": "ศรีสะเกษ",
                        "name_en": "Si Sa Ket",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 23,
                        "name_th": "อุบลราชธานี",
                        "name_en": "Ubon Ratchathani",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 24,
                        "name_th": "ยโสธร",
                        "name_en": "Yasothon",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 25,
                        "name_th": "ชัยภูมิ",
                        "name_en": "Chaiyaphum",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 26,
                        "name_th": "อำนาจเจริญ",
                        "name_en": "Amnat Charoen",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 27,
                        "name_th": "หนองบัวลำภู",
                        "name_en": "Nong Bua Lam Phu",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 28,
                        "name_th": "ขอนแก่น",
                        "name_en": "Khon Kaen",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 29,
                        "name_th": "อุดรธานี",
                        "name_en": "Udon Thani",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 30,
                        "name_th": "เลย",
                        "name_en": "Loei",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 31,
                        "name_th": "หนองคาย",
                        "name_en": "Nong Khai",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 32,
                        "name_th": "มหาสารคาม",
                        "name_en": "Maha Sarakham",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 33,
                        "name_th": "ร้อยเอ็ด",
                        "name_en": "Roi Et",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 34,
                        "name_th": "กาฬสินธุ์",
                        "name_en": "Kalasin",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 35,
                        "name_th": "สกลนคร",
                        "name_en": "Sakon Nakhon",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 36,
                        "name_th": "นครพนม",
                        "name_en": "Nakhon Phanom",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 37,
                        "name_th": "มุกดาหาร",
                        "name_en": "Mukdahan",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 38,
                        "name_th": "เชียงใหม่",
                        "name_en": "Chiang Mai",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 39,
                        "name_th": "ลำพูน",
                        "name_en": "Lamphun",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 40,
                        "name_th": "ลำปาง",
                        "name_en": "Lampang",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 41,
                        "name_th": "อุตรดิตถ์",
                        "name_en": "Uttaradit",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 42,
                        "name_th": "แพร่",
                        "name_en": "Phrae",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 43,
                        "name_th": "น่าน",
                        "name_en": "Nan",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 44,
                        "name_th": "พะเยา",
                        "name_en": "Phayao",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 45,
                        "name_th": "เชียงราย",
                        "name_en": "Chiang Rai",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 46,
                        "name_th": "แม่ฮ่องสอน",
                        "name_en": "Mae Hong Son",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 47,
                        "name_th": "นครสวรรค์",
                        "name_en": "Nakhon Sawan",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 48,
                        "name_th": "อุทัยธานี",
                        "name_en": "Uthai Thani",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 49,
                        "name_th": "กำแพงเพชร",
                        "name_en": "Kamphaeng Phet",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 50,
                        "name_th": "ตาก",
                        "name_en": "Tak",
                        "geography_id": 4,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 51,
                        "name_th": "สุโขทัย",
                        "name_en": "Sukhothai",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 52,
                        "name_th": "พิษณุโลก",
                        "name_en": "Phitsanulok",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 53,
                        "name_th": "พิจิตร",
                        "name_en": "Phichit",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 54,
                        "name_th": "เพชรบูรณ์",
                        "name_en": "Phetchabun",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 55,
                        "name_th": "ราชบุรี",
                        "name_en": "Ratchaburi",
                        "geography_id": 4,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 56,
                        "name_th": "กาญจนบุรี",
                        "name_en": "Kanchanaburi",
                        "geography_id": 4,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 57,
                        "name_th": "สุพรรณบุรี",
                        "name_en": "Suphan Buri",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 58,
                        "name_th": "นครปฐม",
                        "name_en": "Nakhon Pathom",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 59,
                        "name_th": "สมุทรสาคร",
                        "name_en": "Samut Sakhon",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 60,
                        "name_th": "สมุทรสงคราม",
                        "name_en": "Samut Songkhram",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 61,
                        "name_th": "เพชรบุรี",
                        "name_en": "Phetchaburi",
                        "geography_id": 4,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 62,
                        "name_th": "ประจวบคีรีขันธ์",
                        "name_en": "Prachuap Khiri Khan",
                        "geography_id": 4,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 63,
                        "name_th": "นครศรีธรรมราช",
                        "name_en": "Nakhon Si Thammarat",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 64,
                        "name_th": "กระบี่",
                        "name_en": "Krabi",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 65,
                        "name_th": "พังงา",
                        "name_en": "Phangnga",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 66,
                        "name_th": "ภูเก็ต",
                        "name_en": "Phuket",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 67,
                        "name_th": "สุราษฎร์ธานี",
                        "name_en": "Surat Thani",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 68,
                        "name_th": "ระนอง",
                        "name_en": "Ranong",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 69,
                        "name_th": "ชุมพร",
                        "name_en": "Chumphon",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 70,
                        "name_th": "สงขลา",
                        "name_en": "Songkhla",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 71,
                        "name_th": "สตูล",
                        "name_en": "Satun",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 72,
                        "name_th": "ตรัง",
                        "name_en": "Trang",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 73,
                        "name_th": "พัทลุง",
                        "name_en": "Phatthalung",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 74,
                        "name_th": "ปัตตานี",
                        "name_en": "Pattani",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 75,
                        "name_th": "ยะลา",
                        "name_en": "Yala",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 76,
                        "name_th": "นราธิวาส",
                        "name_en": "Narathiwat",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 77,
                        "name_th": "บึงกาฬ",
                        "name_en": "buogkan",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    }
                ],
            };
        },
        methods: {
            setCurrentItem(itemNow) {
        this.CurrentItem = itemNow;
        // if (this.CurrentItem.username == localStorage.getItem('username')) {
        //   this.errorDelete =true;
        // }else{
        //   this.errorDelete=false;
        // }
      },
            async gethardwareByAdmin() {
                const response = await fetch(process.env.VUE_APP_API_URL+"/api/hardware/all", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    }
                });
                var data = await response.json();
                console.log(data.status);
                this.listHardware = data;
            },
            async addhardwareByAdmin() {
                const response = await fetch(process.env.VUE_APP_API_URL+"/api/hardware/add", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin")
                    },
                    body: JSON.stringify({
                        name: this.addnewdeviceModel.banner,
                        model: this.addnewdeviceModel.model
                    }),
                });
                var data = await response;
                console.log(data.status);
                if (data.status == 200) {
                    this.gethardwareByAdmin();
                }
            },
            async editHardwareByAdmin() {
                const response = await fetch(process.env.VUE_APP_API_URL+"/api/hardware/update", {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    },
                    body: JSON.stringify({
                        id:this.CurrentItem.id,
                        name: this.CurrentItem.name,
                        model: this.CurrentItem.model
                    }),
                });
                var data = await response;
                // console.log(data.status);
                if (data.status == 200) {
                    this.gethardwareByAdmin();  
                }
      },
      async deleteHardwareByAdmin() {
                const response = await fetch(process.env.VUE_APP_API_URL+"/api/hardware/delete/"+this.CurrentItem.id,{
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    },
                });
                var data = await response;
                // console.log(data.status);
                if (data.status == 200) {
                    this.gethardwareByAdmin();  
                }
      },


            readRole(){
        var arearole = localStorage.getItem("adminrole");
        if(arearole=="ALL"){
          this.roleArea="ทั้งหมด";
        }else{
          this.roleArea = arearole;
        }
      }
        },
        created() {
            checktokenAPI.check();
            this.readRole();
            this.gethardwareByAdmin();
        }
    };
</script>

<style>
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #dddddd;
    }
</style>