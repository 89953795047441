<template>

  <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-center text-success">
            สร้างเขตพื้นที่สำเร็จ
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Edituser -->
  <div class="modal fade" id="edituserModal" tabindex="-1" aria-labelledby="edituserModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="edituserModalLabel">แก้ไขผู้ใช้งาน</h5>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body" v-if="timeoutLoginState==true">
          <div class="card-body px-5" style="width: 100%;">
            <img src="../../assets/logo.png" alt="" style="width: 350px;">
            <!-- 2 column grid layout with text inputs for the first and last names -->
            <h5 class="mt-2 mb-3 text-center" style="width: 100%;">
              เข้าสู่ระบบอีกครั้ง
            </h5>

            <!-- Email input -->
            <div class="form-outline mb-4">
              <input type="text" v-model="insideLogin.username" class="form-control" />
              <label class="form-label" for="form3Example3">Username</label>
            </div>

            <!-- Password input -->
            <div class="form-outline mb-4">
              <input type="password" v-model="insideLogin.password" class="form-control" />
              <label class="form-label">Password</label>
            </div>
            <div class="text-center mb-2 text-danger">{{ insideLogin.ErrorMessage }}</div>
            <!-- Submit button -->
            <div class="text-center">
              <button type="submit" class="btn btn-warning btn-block mb-4 text-white" @click="reloginWhenTimeOut()"
                style="width: 200px;">
                เข้าสู่ระบบ
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body" v-if="timeoutLoginState==false">
          <div class="">
            <!-- {{ CurrentItem }} -->
            <div>
              <span>ชื่อ : </span><input v-model="CurrentItem.firstName" placeholder="ชื่อ"
                style="width: 100%; height: 35px;" />
            </div>
            <div>
              <span>นามสกุล : </span><input v-model="CurrentItem.lastName" placeholder="นามสกุล"
                style="width: 100%; height: 35px;" />
            </div>
            <div class="mt-3">
              <span>ผู้ใช้งาน : </span><input v-model="CurrentItem.username" placeholder="Username"
                style="width: 100%; height: 35px;" />
            </div>

            <div class="mt-4">
              <span>Email:</span><input type="email" v-model="CurrentItem.email" placeholder="Email"
                style="width: 100%; height: 35px;" />
            </div>
            <div class="mt-5">
              <select v-model="addnewuser.role" style="width: 100%; height: 35px;">
                <option disabled value="">เลือกสิทธิ์การใช้งาน</option>
                <option>user</option>
              </select>
            </div>
            
            <div class="mt-2" v-if="addnewuser.role=='user'">
              <select v-if="roleArea=='ทั้งหมด'" v-model="addnewuser.area" style="width: 100%; height: 35px;">
                <option disabled value="">เลือกพื้นที่</option>
                <option class="" v-for="(itemArea, i) in ListAreaItem" :key="i">
                  <div class="dropdown-item" v-if="itemArea.name!='ALL'" href="#">{{ itemArea.name }}</div>

                </option>
              </select>
              <!-- <select v-else v-model="addnewuser.area" style="width: 100%; height: 35px;">
                <option disabled value="">{{ roleArea }}</option>
              </select> -->
            </div>

            <div class="mt-2" v-if="CurrentItem.roles.name=='user'||CurrentItem.roles.name=='admin'">
              <input type="checkbox" id="checkbox" v-model="CurrentItem.allzone" />
              <label class="ms-1" for="checkbox"> เลือกทุกโซน</label>

              <div v-if="CurrentItem.allzone==false">
                <div class="row">
                  <div class="col-10">
                    <select v-if="roleArea=='ทั้งหมด'" v-model="CurrentItem.area" style="width: 100%; height: 35px;">
                      <option disabled value="">เลือกพื้นที่</option>
                      <option class="" v-for="(itemArea, i) in ListAreaItem" :key="i">
                        <div class="dropdown-item">{{ itemArea.name }}</div>
                      </option>
                    </select>
                    <select v-else v-model="CurrentItem.area" style="width: 100%; height: 35px;">
                      <!-- <option disabled value="">{{ roleArea }}</option> -->
                      <option disabled value="">เลือกพื้นที่</option>
                      <option class="" v-for="(itemArea, i) in roleArea" :key="i">
                        <div class="dropdown-item">{{ itemArea }}</div>
                      </option>
                    </select>
                  </div>
                  <div class="col-2 pt-1">
                    <button style=".btn {
                  display:block;
                  height: 30px;
                  width: 30px;
                  border-radius: 50%;
                  background-color:#FCA585;
                  }" @click="ccc(CurrentItem.area)">+ เพิ่ม</button>
                  </div>
                </div>
                <div>
                  <div class="mt-3" style="font-weight: bold;">
                    พื้นที่ดูแล
                  </div>
                  <div class="mt-1 border bg-light pt-1" style="height: 50px;">
                    <span class="" v-for="(rArea, i) in resArea" :key="i">
                      <span class="border rounded" href="#"
                        style="background-color: rgb(175, 175, 175);">&nbsp;{{rArea}}
                        <font-awesome-icon type="button" @click="removeResArea(i,rArea)" style="color: white;"
                          icon="fa-solid fa-xmark" />&nbsp; </span><span style="width: 20px;">&nbsp;</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button v-if="timeoutLoginState==false" type="button" class="btn btn-warning" @click="edituserByAdmin()"
            data-bs-dismiss="modal">แก้ไข</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Adduser -->
  <div class="modal fade" id="adduserModal" tabindex="-1" aria-labelledby="adduserModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="adduserModalLabel">+ สร้างผู้ใช้งาน</h5>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body" v-if="timeoutLoginState==true">
          <div class="card-body px-5" style="width: 100%;">
            <img src="../../assets/logo.png" alt="" style="width: 350px;">
            <!-- 2 column grid layout with text inputs for the first and last names -->
            <h5 class="mt-2 mb-3 text-center" style="width: 100%;">
              เข้าสู่ระบบอีกครั้ง
            </h5>

            <!-- Email input -->
            <div class="form-outline mb-4">
              <input type="text" v-model="insideLogin.username" class="form-control" />
              <label class="form-label" for="form3Example3">Username</label>
            </div>

            <!-- Password input -->
            <div class="form-outline mb-4">
              <input type="password" v-model="insideLogin.password" class="form-control" />
              <label class="form-label">Password</label>
            </div>
            <div class="text-center mb-2 text-danger">{{ insideLogin.ErrorMessage }}</div>
            <!-- Submit button -->
            <div class="text-center">
              <button type="submit" class="btn btn-warning btn-block mb-4 text-white" @click="reloginWhenTimeOut()"
                style="width: 200px;">
                เข้าสู่ระบบ
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body" v-if="timeoutLoginState==false">
          <div class="">
            <div>
              <span>ชื่อ : </span><input v-model="addnewuser.firstname" placeholder="ชื่อ"
                style="width: 100%; height: 35px;" />
            </div>
            <div>
              <span>นามสกุล : </span><input v-model="addnewuser.lastname" placeholder="นามสกุล"
                style="width: 100%; height: 35px;" />
            </div>
            <div class="mt-3">
              <span>ผู้ใช้งาน : </span><input v-model="addnewuser.username" placeholder="Username"
                style="width: 100%; height: 35px;" />
            </div>
            <div class="mt-2">
              <span>รหัสผ่าน:</span><input type="password" v-model="addnewuser.password" placeholder="Password"
                style="width: 100%; height: 35px;" />
            </div>
            <div v-if="addnewuser.password.length>=1&&addnewuser.password.length<8" style="color:red;font-size: 12px;">
              รหัสผ่านต้องมากกว่า 8 ตัวอักษร</div>

            <div class="mt-4">
              <span>Email:</span><input type="email" v-model="addnewuser.email" placeholder="Email"
                style="width: 100%; height: 35px;" />
            </div>
            <div class="mt-5" v-if="roleArea=='ทั้งหมด'">
              <select v-model="addnewuser.role" style="width: 100%; height: 35px;">
                <option disabled value="">เลือกสิทธิ์การใช้งาน</option>
                <option>admin</option>
                <option>user</option>
              </select>
            </div>
            <div class="mt-5" v-else>
              <select v-model="addnewuser.role" style="width: 100%; height: 35px;">
                <option disabled value="">เลือกสิทธิ์การใช้งาน</option>
                <option>user</option>
              </select>
            </div>
            <div class="mt-2" v-if="addnewuser.role=='user'||addnewuser.role=='admin'">
              <input type="checkbox" id="checkbox" v-model="addnewuser.allzone" />
              <label class="ms-1" for="checkbox"> เลือกทุกโซน</label>

              <div v-if="addnewuser.allzone==false">
                <div class="row">
                  <div class="col-10">
                    <select v-if="roleArea=='ทั้งหมด'" v-model="addnewuser.area" style="width: 100%; height: 35px;">
                      <option disabled value="">เลือกพื้นที่</option>
                      <option class="" v-for="(itemArea, i) in ListAreaItem" :key="i">
                        <div class="dropdown-item">{{ itemArea.name }}</div>
                      </option>
                    </select>
                    
                    <select v-else v-model="addnewuser.area" style="width: 100%; height: 35px;">
                      <option disabled value="">เลือกพื้นที่</option>
                      <option class="" v-for="(itemArea, i) in roleArea" :key="i">
                        <div class="dropdown-item">{{ itemArea }}</div>
                      </option>
                      <!-- <option disabled value="">{{ roleArea }}</option> -->
                      <!-- <option ><div class="dropdown-item">{{ roleArea }}</div></option> -->
                    </select>
                  </div>

                  <div class="col-2 pt-1">
                    <button style=".btn {
                    display:block;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    background-color:#FCA585;  
                    }" @click="ccc(addnewuser.area)">+ เพิ่ม</button>
                  </div>
                </div>
                <div>
                  <div class="mt-3" style="font-weight: bold;">
                    พื้นที่ดูแล
                  </div>
                  <div class="mt-1 border bg-light pt-1" style="height: 50px;">
                    <span class="" v-for="(rArea, i) in resArea" :key="i">
                      <span class="border rounded" href="#"
                        style="background-color: rgb(175, 175, 175);">&nbsp;{{rArea}}
                        <font-awesome-icon type="button" @click="removeResArea(i,rArea)" style="color: white;"
                          icon="fa-solid fa-xmark" />&nbsp; </span><span style="width: 20px;">&nbsp;</span>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button v-if="timeoutLoginState==false" type="button" class="btn btn-warning" @click="addnewuserByAdmin()"
            data-bs-dismiss="modal">+
            สร้าง</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete user -->
  <div class="modal fade" id="deleteuserModal" tabindex="-1" aria-labelledby="deleteuserModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteuserModalLabel">ลบผู้ใช้งาน</h5>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body" v-if="timeoutLoginState==true">
          <div class="card-body px-5" style="width: 100%;">
            <img src="../../assets/logo.png" alt="" style="width: 350px;">
            <!-- 2 column grid layout with text inputs for the first and last names -->
            <h5 class="mt-2 mb-3 text-center" style="width: 100%;">
              เข้าสู่ระบบอีกครั้ง
            </h5>

            <!-- Email input -->
            <div class="form-outline mb-4">
              <input type="text" v-model="insideLogin.username" class="form-control" />
              <label class="form-label" for="form3Example3">Username</label>
            </div>

            <!-- Password input -->
            <div class="form-outline mb-4">
              <input type="password" v-model="insideLogin.password" class="form-control" />
              <label class="form-label">Password</label>
            </div>
            <div class="text-center mb-2 text-danger">{{ insideLogin.ErrorMessage }}</div>
            <!-- Submit button -->
            <div class="text-center">
              <button type="submit" class="btn btn-warning btn-block mb-4 text-white" @click="reloginWhenTimeOut()"
                style="width: 200px;">
                เข้าสู่ระบบ
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body" v-if="timeoutLoginState==false">
          <div v-if="errorDelete==false" class="text-center">ยืนยันการลบผู้ใช้งาน</div>
          <div v-if="errorDelete==true" class="text-center">ไม่สามารถลบผู้ใช้งานของตนเองได้</div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button v-if="timeoutLoginState==false&&errorDelete==false" type="button" class="btn btn-danger"
            @click="deleteuserByAdmin()" data-bs-dismiss="modal">
            ลบ</button>
        </div>
      </div>
    </div>
  </div>


  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="../../assets/logo.png" style="width: 250px;" alt="">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <router-link to="/zoneadmin/map" style="text-decoration: none;">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">อุปกรณ์บนแผนที่</a>
            </li>
          </router-link>
          <router-link to="/zoneadmin/adduser" style="text-decoration: none;">
            <li class="nav-item">
              <a class="nav-link text-warning" aria-current="page" href="#">ผู้ใช้งาน</a>
            </li>
          </router-link>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              อุปกรณ์
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link to="/zoneadmin/detailnamebox" style="text-decoration: none;"><a class="dropdown-item"
                    href="">รายละเอียดกล่อง</a>
                </router-link>
              </li>
              <li>
                <router-link to="/zoneadmin/detaildevice" style="text-decoration: none;"><a class="dropdown-item"
                    href="">รายละเอียดที่ตั้งอุปกรณ์</a></router-link>
              </li>
              <li>
                <router-link to="/zoneadmin/detailhardware" style="text-decoration: none;"><a class="dropdown-item"
                    href="#">รายละเอียดรุ่นอุปกรณ์</a></router-link>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <router-link to="/zoneadmin/statusdevice" style="text-decoration: none;"><a class="dropdown-item"
                    href="#">รายงานอุปกรณ์ออฟไลน์</a></router-link>
              </li>
              <!-- <li><a class="dropdown-item" href="#">รายงานอุปกรณ์ออฟไลน์</a></li> -->
            </ul>
          </li>
        </ul>
        <form class="d-flex">
          <div class="col-12 text-end">
            <button class="btn btn-outline-warning ms-2" type="button" data-bs-toggle="modal"
              data-bs-target="#adduserModal" @click="timeOut(),getAreaAll()">+ สร้างผู้ใช้งาน</button>
          </div>

        </form>
      </div>
    </div>
  </nav>

  <body>
    <div>
      <div v-if="showPopup" class="popup rounded ">
        <font-awesome-icon icon="fa-solid fa-circle-check" style="font-size: 80px;color:#32CD32;" />
        <p class="text-center mt-2">สำเร็จ</p>
      </div>
    </div>
    <div style="overflow-x:auto;">
      <table class="">

        <tr>
          <th style="min-width: 180px;">Username</th>
          <th style="min-width: 120px;">พิ้นที่</th>
          <th style="min-width: 120px;">ชื่อ</th>
          <th style="min-width: 120px;">นามสกุล</th>
          <th style="min-width: 120px;">Email</th>
          <th style="min-width: 120px;">ระดับการเข้าถึง</th>
          <th style="min-width: 100px;">แก้ไข</th>
        </tr>
        <tr v-for="(UserItem, i) in ListUserItem" :key="i">
          <td>{{ UserItem.username }}</td>
          <td>{{ formattedZones(UserItem.zones) }}</td>
          <!-- <td>{{ UserItem.zones }}</td> -->
          <td>{{ UserItem.firstName }}</td>
          <td>{{ UserItem.lastName }}</td>
          <td>{{ UserItem.email }}</td>
          <td>{{ UserItem.roles.name }}</td>
          <td class="text-start">
            <span>
              <font-awesome-icon type="button" data-bs-toggle="modal" data-bs-target="#edituserModal"
                style="color: skyblue;" icon="fa-solid fa-pen"
                @click="timeOut(),getAreaAll(),setCurrentItem(UserItem)" />

            </span>
            <span class="ms-3">
              <font-awesome-icon type="button" data-bs-toggle="modal" data-bs-target="#deleteuserModal"
                style="color: #FFBBAC;" icon="fa-solid fa-box-archive" @click="timeOut(),setCurrentItem(UserItem)" />
            </span></td>

        </tr>
      </table>
    </div>

  </body>

</template>
<script>
import checktokenAPI from "../connectBackend/checkTokenFirst";
  import CheckTimeService from '../timeVerify/timeoutVerify'
  export default {
    data() {
      return {
        resArea: [],
        roleArea: '',
        CurrentItem: {
          id: null,
          firstName: '',
          lastName: '',
          roles: {
            name: ''
          },
          zones: [{
            id: null,
            name: ''
          }],
          allzone: null
        },
        errorDelete: false,
        timeoutLoginState: false,
        addnewuser: {
          email: '',
          username: '',
          password: '',
          role: '',
          area: '',
          firstname: '',
          lastname: '',
          allzone: false
        },
        ListUserItem: [],
        ListAreaItem: [],
        showPopup: false,
        insideLogin: {
          username: '',
          password: '',
          ErrorMessage: ''
        },
      };
    },
    methods: {
      removeResArea(i, v) {
        var index = this.resArea.indexOf(v); // Get the index of the element to remove
        if (index > -1) {
          this.resArea.splice(index, 1); // Remove the element at the specified index
        }
      },
      ccc(ian) {
        this.resArea.push(ian);
        this.addnewuser.area = ""
      },
      setCurrentItem(itemNow) {
        this.CurrentItem = itemNow;
        if (this.CurrentItem.username == localStorage.getItem('username')) {
          this.errorDelete = true;
        } else {
          this.errorDelete = false;
        }
        this.resArea = this.CurrentItem.zones;
      },
      timeOut() {
        var statusTimeout = CheckTimeService.checkTimeOut();
        this.timeoutLoginState = statusTimeout;
      },
      async addnewuserByAdmin() {
        if (this.addnewuser.allzone == true) {
          this.resArea = [];
        }
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/auth/signup", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: this.addnewuser.username,
            password: this.addnewuser.password,
            firstName: this.addnewuser.firstname,
            lastName: this.addnewuser.lastname,
            email: this.addnewuser.email,
            roles: [this.addnewuser.role],
            zone: this.resArea,
            allzone: this.addnewuser.allzone,
          }),
        });
        var dataStatus = await response.status;
        var data = await response.json();
        if (dataStatus == 200) {
          this.showPopupAfterDelay();
          this.getuserAll();
        }
        console.log(data);
      },
      async edituserByAdmin() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/users/update", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
          },
          body: JSON.stringify({
            id: this.CurrentItem.id,
            username: this.CurrentItem.username,
            firstName: this.CurrentItem.firstName,
            lastName: this.CurrentItem.lastName,
            email: this.CurrentItem.email,
            roles: [this.CurrentItem.roles.name], // admin , user
            zone: this.resArea,
            allzone: this.CurrentItem.allzone
          }),
        });
        var dataStatus = await response.status;
        var data = await response.json();
        if (dataStatus == 200) {
          this.showPopupAfterDelay();
          this.getuserAll();
        }
        console.log(data);
      },
      async deleteuserByAdmin() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/users/delete/" + this.CurrentItem.id, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
          },
        });

        console.log(response);
        this.getuserAll();
      },

      async getuserAll() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/users/all", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
          },
        });
        var data = await response.json();
        this.ListUserItem = data;
        console.log(data);
      },
      async getAreaAll() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/zones/all", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
          },
        });
        var data = await response.json();
        this.ListAreaItem = await data;
        console.log(data);
      },
      showPopupAfterDelay() {
        this.showPopup = true;
        setTimeout(() => {
          this.showPopup = false;
        }, 1000);
      },
      async reloginWhenTimeOut() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/auth/signin", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: this.insideLogin.username,
            password: this.insideLogin.password
          }),
        });
        var data = await response.json();
        if (data.accessToken == null) {
          this.ErrorMessage = data.message;
        } else {
          this.insideLogin.ErrorMessage = "";
          if (data.roles == "ROLE_admin") {
            localStorage.setItem("token_admin", data.accessToken);
            localStorage.removeItem("token_user");
            localStorage.setItem("verify_time", Date.now());
            this.timeOut();
            location.reload();
          } else if (data.roles == "ROLE_user") {
            this.ErrorMessage = "Role Error";
          }
        }
        console.log(data);
      },
      readRole() {
        var arearole = localStorage.getItem("adminrole_state");
        if (arearole == 'true') {
          this.roleArea = "ทั้งหมด";
        } else {
          var local = localStorage.getItem("adminrole");
          this.roleArea = local.split(",");
        }
      },
      formattedZones(z) {
        return z.join(', '); // Join the elements with a comma and space
      }
    },
    created() {
      checktokenAPI.check();
      this.readRole();
      this.getuserAll();
    }
  };
</script>

<style>
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
</style>