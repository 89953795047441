// import axios from 'axios';

// const API_URL = 'http://webcode.me/';

class checkTokenOn{
    async check(){
        const response = await fetch(process.env.VUE_APP_API_URL+"/api/test/admin", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": localStorage.getItem("token_admin"),
            },
        });
        var data = await response.status;
    //    console.log(data)
       if(data!=200){
        
        localStorage.removeItem("token_admin");
        localStorage.removeItem("adminrole");
        localStorage.removeItem("adminrole_state");   
        localStorage.removeItem("infolocations");
        localStorage.removeItem("username");
        localStorage.removeItem("verify_time");
        localStorage.removeItem("partcctv");
        var currentUrl = window.location.href;
        var baseUrl = currentUrl.replace(currentUrl, "/login");
        location.href = baseUrl;

       }
    }
}

export default new checkTokenOn();