<template>
  <!-- Modal Search -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">การค้นหาอุปกรณ์</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-4">
              </div>
              <div class="col-3 pt-2 text-end" style="font-weight:bold;">
                เขตพื้นที่ :
              </div>
              <div class="col-5 mt-2 text-end">

                <select v-model="fillterAll.zoneId" style="width: 100%; height: 35px;">
                  <option disabled value="">เลือกพื้นที่</option>
                  <option class="" v-for="(itemArea, i) in ListAreaItem" :key="i">
                    <div class="dropdown-item" href="#">{{ itemArea.name }}</div>
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-4">
              </div>
              <div class="col-3 pt-2 text-end" style="font-weight:bold;">
                อุปกรณ์ :
              </div>
              <div class="col-5">
                <input v-model="fillterAll.nameDevice" placeholder="ชื่ออุปกรณ์" style="width: 100%; height: 35px;" />
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-4">
              </div>
              <div class="col-3 pt-2 text-end" style="font-weight:bold;">
                สถานะ :
              </div>
              <div class="col-5 mt-2 text-end">

                <select v-model="fillterAll.Status" style="width: 100%; height: 35px;">
                  <option disabled value="">เลือกสถานะ</option>
                  <option>Online</option>
                  <option>Offline</option>
                </select>
              </div>
            </div> -->
<!-- 
            <div class="row">
              <div class="col-4">
              </div>
              <div class="col-3 pt-2 text-end" style="font-weight:bold;">
                ชนิดอุปกรณ์ :
              </div>
              <div class="col-5 mt-2 text-end">

                <select v-model="fillterAll.Type" style="width: 100%; height: 35px;">
                  <option disabled value="">เลือกชนิดอุปกรณ์</option>
                  <option>camera</option>
                  <option>speaker</option>
                </select>
              </div>
            </div> -->

            <hr>
            <div class="row">
              <div class="col-4">
              </div>
              <div class="col-3 pt-2 text-end" style="font-weight:bold;">
                จังหวัด :
              </div>
              <div class="col-5 mt-2 text-end">

                <select v-model="fillterAll.Province" style="width: 100%; height: 35px;">
                  <option disabled value="">เลือกจังหวัดทั้งหมด</option>
                  <option class="" v-for="(itemProvince, i) in provinces" :key="i">
                    <div class="dropdown-item" href="#">{{ itemProvince.name_th }}</div>
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-4">
              </div>
              <div class="col-3 pt-2 text-end" style="font-weight:bold;">
                รหัสไปรษณีย์ :
              </div>
              <div class="col-5">
                <input v-model="fillterAll.ZipCode" placeholder="กรอกรหัสไปรษณีย์" style="width: 100%; height: 35px;" />
              </div>
            </div>



          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="fillterDeviceByAdmin()"
            data-bs-dismiss="modal">ค้นหาอุปกรณ์</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal AllPin -->
  <div class="modal fade" id="pinModal" tabindex="-1" aria-labelledby="pinModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="pinModalLabel">รายการปักหมุดบนแผนที่</h5>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body">
          <!-- <div>{{ localStorageParamiter.location}}</div> -->
          <table>
            <tr>
              <th>NO</th>
              <th>ชื่อที่ตั้ง</th>
            </tr>
            <tr li v-for="(item, index) in localStorageParamiter.location" :key="index">
              <td>{{ index+1 }}</td>
              <td>{{item}}</td>
            </tr>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-danger" @click="removeLocalStorage()">Clear</button>
          <router-link to="/supperadmin/detaildevice"><button type="button" class="btn btn-warning" data-bs-dismiss="modal"
              @click="navigateToDevice()">ไปยังรายละเอียดที่ตั้งอุปกรณ์</button></router-link>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal All cctv -->
  <div class="modal fade" id="cctvAllModal" tabindex="-1" aria-labelledby="cctvAllModalLabel" aria-hidden="true">
   
   <div class="modal-dialog modal-lg modal-dialog-centered">
     <div class="modal-content">
       <div class="modal-header">
         <h5 class="modal-title" id="cctvAllModalLabel">CCTV Realtime</h5>
       </div>
       <div class="modal-body row justify-content-center">
         <!-- <div>{{ itemCCTVindex }}</div> -->
         
         <div v-if="markers.length!=0" class=" col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
           <iframe v-for="(partitem, i) in markers[itemCCTVindex].device" :key="i" width="109%" height="335"
             :src='`${rtspUrl}/live/${partitem.part}`' frameborder="0" allowfullscreen></iframe>
         </div>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="removeCCTVLocal()">
           Close</button>
       </div>
     </div>
   </div>
 </div>

  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <div class="container-fluid">

      <router-link class="navbar-brand" to="/supperadmin/landing">
        <img src="../../assets/logo.png" style="width: 250px;" alt="">
      </router-link>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <router-link to="/supperadmin/map" style="text-decoration: none;">
            <li class="nav-item">
              <a class="nav-link active text-warning" aria-current="page" href="#">อุปกรณ์บนแผนที่</a>
            </li>
          </router-link>
          <!-- v-if="roleArea=='ทั้งหมด'" -->
          <router-link to="/supperadmin/area" style="text-decoration: none;">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#">เขตพื้นที่</a>
            </li>
          </router-link>
          <router-link to="/supperadmin/adduser" style="text-decoration: none;">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#">ผู้ใช้งาน</a>
            </li>
          </router-link>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              อุปกรณ์
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link to="/supperadmin/detailnamebox" style="text-decoration: none;"><a class="dropdown-item"
                    href="">รายละเอียดกล่อง</a>
                </router-link>
              </li>
              <li>
                <router-link to="/supperadmin/detaildevice" style="text-decoration: none;"><a class="dropdown-item"
                    href="">รายละเอียดที่ตั้งอุปกรณ์</a></router-link>
              </li>
              <li>
                <router-link to="/supperadmin/detailhardware" style="text-decoration: none;"><a class="dropdown-item"
                    href="#">รายละเอียดรุ่นอุปกรณ์</a></router-link>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <router-link to="/supperadmin/statusdevice" style="text-decoration: none;"><a class="dropdown-item"
                    href="#">รายงานอุปกรณ์ออฟไลน์</a></router-link>
              </li>
              <!-- <li><a class="dropdown-item" href="#">รายงานอุปกรณ์ออฟไลน์</a></li> -->
            </ul>
          </li>
        </ul>
        <form class="d-flex">

          <!-- <button @click="getAreaAll()" class="btn btn-outline-primary" type="button" data-bs-toggle="modal"
            data-bs-target="#exampleModal">
            <font-awesome-icon icon="fa-solid fa-search" /> ค้นหาบนแผนที่</button> -->
          <button class="btn btn-outline-warning ms-2" type="button" data-bs-toggle="modal" data-bs-target="#pinModal">
            <!-- <font-awesome-icon icon="fa-solid fa-book" />  -->
            <span class="row">
              <div class="col-9">
                <font-awesome-icon icon="fa-solid fa-book" /> รายการปักหมุด
              </div>
              <div class="col-2">
                <p class="text-white bg-warning rounded-circle" style="width: 25px;height: 25px;">
                  {{ localStorageParamiter.length }}</p>
              </div>
            </span>
          </button>
        </form>
      </div>
    </div>

  </nav>

  <div class="bg-light" style="height: 8.0vh;">
  </div>
  <!-- <longdo-map @load="loadMap" style="height: 100vh;width: 100%;">
    <longdo-map-marker :location="{ lon: 99, lat: 14 }" :popup="{html:'<div>TEST</div>'}" />
  </longdo-map> -->

  <longdo-map  @load="test" :lastView="true"    style="height: 91.5vh;">
    <longdo-map-marker v-for="(item, i) in markers" :key="i" :location="item.location" :popup='{html:`
<div class="links" style=" border: 2px solid white; background-color:rgb(255 255 255 / 45%);height: 120px;
  width: 120px;
  border-radius: 50%;
  display: inline-block;">

  <ul class="links__list" style="--item-total:6">
    <li class="links__item" style="--item-count:1 ">

     
      <a class="links__link" data-bs-toggle="modal" data-bs-target="#cctvAllModal"  onclick="
        localStorage.setItem(&#39;partcctv&#39;, &#39;${i}&#39;);">
           <svg class="links__icon" viewBox="0 0 20 20">
							<path d="M17.919,4.633l-3.833,2.48V6.371c0-1-0.815-1.815-1.816-1.815H3.191c-1.001,0-1.816,0.814-1.816,1.815v7.261c0,1.001,0.815,1.815,1.816,1.815h9.079c1.001,0,1.816-0.814,1.816-1.815v-0.739l3.833,2.478c0.428,0.226,0.706-0.157,0.706-0.377V5.01C18.625,4.787,18.374,4.378,17.919,4.633 M13.178,13.632c0,0.501-0.406,0.907-0.908,0.907H3.191c-0.501,0-0.908-0.406-0.908-0.907V6.371c0-0.501,0.407-0.907,0.908-0.907h9.079c0.502,0,0.908,0.406,0.908,0.907V13.632zM17.717,14.158l-3.631-2.348V8.193l3.631-2.348V14.158z"></path>
						</svg>
        <span class="links__text">CCTV</span></a>
    </li>
    <li class="links__item" style="--item-count:2">
      <a class="links__link" href="javascript:;" onclick="
      
      var oldLocations=[localStorage.getItem(&#39;locations&#39;)]
      var newLocation = &#39;${item.name}&#39;;
      if(oldLocations==null || oldLocations==&#39;&#39;){
        oldLocations = [newLocation];
      }else{
        oldLocations.push(newLocation);
      }
      localStorage.setItem(&#39;locations&#39;, [oldLocations]);

      var oldLocationsID=[localStorage.getItem(&#39;locationsID&#39;)]
      var newLocationID = &#39;${item.id}&#39;;
      if(oldLocationsID==null || oldLocationsID==&#39;&#39;){
        oldLocationsID = [newLocationID];
      }else{
        oldLocationsID.push(newLocationID);
      }
      localStorage.setItem(&#39;locationsID&#39;, [oldLocationsID]);

      console.log(localStorage.getItem(&#39;locations&#39;));
      " >
        <svg class="links__icon" viewBox="0 0 20 20">
							<path d="M17.592,8.936l-6.531-6.534c-0.593-0.631-0.751-0.245-0.751,0.056l0.002,2.999L5.427,9.075H2.491c-0.839,0-0.162,0.901-0.311,0.752l3.683,3.678l-3.081,3.108c-0.17,0.171-0.17,0.449,0,0.62c0.169,0.17,0.448,0.17,0.618,0l3.098-3.093l3.675,3.685c-0.099-0.099,0.773,0.474,0.773-0.296v-2.965l3.601-4.872l2.734-0.005C17.73,9.688,18.326,9.669,17.592,8.936 M3.534,9.904h1.906l4.659,4.66v1.906L3.534,9.904z M10.522,13.717L6.287,9.48l4.325-3.124l3.088,3.124L10.522,13.717z M14.335,8.845l-3.177-3.177V3.762l5.083,5.083H14.335z"></path>
						</svg>
        <span class="links__text">ปักหมุด</span></a>
    </li>
    <li class="links__item" style="--item-count:3">
      <a class="links__link"  onclick="
      var i = &#39;${item.id},${item.name},${item.zone},${item.Province},${item.status},${item.ZipCode},${item.location.lat},${item.location.lon},&#39; 
      localStorage.setItem(&#39;infolocations&#39;, i);
      var currentUrl = window.location.href;
      var baseUrl =  currentUrl.replace(&#39;/supperadmin/map&#39;, &#39;/supperadmin/overall&#39;);
      location.href=baseUrl;


      ">
        <svg class="links__icon" viewBox="0 0 20 20">
							<path  d="M17.125,1.375H2.875c-0.828,0-1.5,0.672-1.5,1.5v11.25c0,0.828,0.672,1.5,1.5,1.5H7.75v2.25H6.625c-0.207,0-0.375,0.168-0.375,0.375s0.168,0.375,0.375,0.375h6.75c0.207,0,0.375-0.168,0.375-0.375s-0.168-0.375-0.375-0.375H12.25v-2.25h4.875c0.828,0,1.5-0.672,1.5-1.5V2.875C18.625,2.047,17.953,1.375,17.125,1.375z M11.5,17.875h-3v-2.25h3V17.875zM17.875,14.125c0,0.414-0.336,0.75-0.75,0.75H2.875c-0.414,0-0.75-0.336-0.75-0.75v-1.5h15.75V14.125z M17.875,11.875H2.125v-9c0-0.414,0.336-0.75,0.75-0.75h14.25c0.414,0,0.75,0.336,0.75,0.75V11.875z M10,14.125c0.207,0,0.375-0.168,0.375-0.375S10.207,13.375,10,13.375s-0.375,0.168-0.375,0.375S9.793,14.125,10,14.125z"></path>
						</svg>
        <span class="links__text" >Overall</span></a>
    </li>
    <li class="links__item" style="--item-count:4">
      <a class="text-center links__link" >
        <div>
          Status   
          </div>
       <div style="color:#2ECC71;">Online</div>
        <span class="links__text" style="color:green"></span></a>
    </li>
    <li class="links__item" style="--item-count:5">
      <a class="links__link" >
        <div>ที่ตั้ง</div>
<div style="font-size:7px;">${item.location.lat} , ${item.location.lon}</div>
        <span class="links__text"></span></a>
    </li>
    <li class="links__item" style="--item-count:6">
      <a class="links__link">
        <svg class="links__icon" viewBox="0 0 20 20">
          <path d="M17.969,10c0,1.707-0.5,3.366-1.446,4.802c-0.076,0.115-0.203,0.179-0.333,0.179c-0.075,0-0.151-0.022-0.219-0.065c-0.184-0.122-0.233-0.369-0.113-0.553c0.86-1.302,1.314-2.812,1.314-4.362s-0.454-3.058-1.314-4.363c-0.12-0.183-0.07-0.43,0.113-0.552c0.186-0.12,0.432-0.07,0.552,0.114C17.469,6.633,17.969,8.293,17.969,10 M15.938,10c0,1.165-0.305,2.319-0.88,3.339c-0.074,0.129-0.21,0.201-0.347,0.201c-0.068,0-0.134-0.016-0.197-0.052c-0.191-0.107-0.259-0.351-0.149-0.542c0.508-0.9,0.776-1.918,0.776-2.946c0-1.028-0.269-2.046-0.776-2.946c-0.109-0.191-0.042-0.434,0.149-0.542c0.193-0.109,0.436-0.042,0.544,0.149C15.634,7.681,15.938,8.834,15.938,10 M13.91,10c0,0.629-0.119,1.237-0.354,1.811c-0.063,0.153-0.211,0.247-0.368,0.247c-0.05,0-0.102-0.01-0.151-0.029c-0.203-0.084-0.301-0.317-0.217-0.521c0.194-0.476,0.294-0.984,0.294-1.508s-0.1-1.032-0.294-1.508c-0.084-0.203,0.014-0.437,0.217-0.52c0.203-0.084,0.437,0.014,0.52,0.217C13.791,8.763,13.91,9.373,13.91,10 M11.594,3.227v13.546c0,0.161-0.098,0.307-0.245,0.368c-0.05,0.021-0.102,0.03-0.153,0.03c-0.104,0-0.205-0.04-0.281-0.117l-3.669-3.668H2.43c-0.219,0-0.398-0.18-0.398-0.398V7.012c0-0.219,0.179-0.398,0.398-0.398h4.815l3.669-3.668c0.114-0.115,0.285-0.149,0.435-0.087C11.496,2.92,11.594,3.065,11.594,3.227 M7.012,7.41H2.828v5.18h4.184V7.41z M10.797,4.189L7.809,7.177v5.646l2.988,2.988V4.189z"></path>
						</svg>
        <span class="links__text" >Active</span></a>
    </li>
  </ul>
</div>`
}' :icon="item.icon" />
  </longdo-map>

  <!-- <img src="./../icon2.png"> -->
  <!-- <button @click="testcallapi.test123('test')">Click me!</button> -->
</template>
<script setup>
// function test(map) {
//   function getLayers (layers) {
//   let result = []
//   for (let layer of layers) {
//     result.push(window.longdo.Layers[layer])
//   }
//   return result
// }

//   // map.Layers.setBase(longdo.Layers.NORMAL)
// }
// function addMarker(marker) {
//   console.log(marker.location())
// }
</script>

<script>
// import longdo from 'longdo-map-vue'


  // var markers=[]
  import checktokenAPI from "../connectBackend/checkTokenFirst";
  
  import '../connectBackend/testcallapi'
  // var iconURLbox = "./../icon2.png"
  var iconURLbox =
    "https://firebasestorage.googleapis.com/v0/b/webdp-f29cb.appspot.com/o/on1.png?alt=media&token=c3b7f407-fdb5-477d-ab66-9a3a23803a1a&_gl=1*16u70kr*_ga*MTI3MTQ3NjgzMC4xNjk0Nzk2OTgz*_ga_CW55HF8NVT*MTY5ODY1NzMzNy41LjEuMTY5ODY1NzQwNS42MC4wLjA.";
  var iconURLboxError =
    "https://firebasestorage.googleapis.com/v0/b/webdp-f29cb.appspot.com/o/off1.png?alt=media&token=02bc214d-4801-4d78-802f-4fbe378ff0c3&_gl=1*oavr5m*_ga*MTI3MTQ3NjgzMC4xNjk0Nzk2OTgz*_ga_CW55HF8NVT*MTY5ODY1NzMzNy41LjEuMTY5ODY1NzQ4MC42MC4wLjA."
  export default {
    components: {

    },
    data: function () {
      return {
        rtspUrl: process.env.VUE_APP_RTSP_URL,
        roleArea: '',
        fillterAll: {
          nameDevice: null,
          Province: null,
          ZipCode: null,
          Status: null,
          Type: null,
          zoneId: [null]
        },
        ListDeviceItem: [],
        ListAreaItem: [],
        localStorageParamiterList: [],
        localStorageParamiter: {
          id: [],
          location: [],
          length: 0,
        },
        myButtonHtml: '<button v-bind:onclick="testhhh()">jjjj</button>',
        nameKey: '111',
        bb: 'alala',
        testsss: "sss",
        status: null,
        loadList:false,
        provinces: [{
            "id": 1,
            "name_th": "กรุงเทพ",
            "name_en": "Bangkok",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 2,
            "name_th": "สมุทรปราการ",
            "name_en": "Samut Prakan",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 3,
            "name_th": "นนทบุรี",
            "name_en": "Nonthaburi",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 4,
            "name_th": "ปทุมธานี",
            "name_en": "Pathum Thani",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 5,
            "name_th": "พระนครศรีอยุธยา",
            "name_en": "Phra Nakhon Si Ayutthaya",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 6,
            "name_th": "อ่างทอง",
            "name_en": "Ang Thong",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 7,
            "name_th": "ลพบุรี",
            "name_en": "Loburi",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 8,
            "name_th": "สิงห์บุรี",
            "name_en": "Sing Buri",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 9,
            "name_th": "ชัยนาท",
            "name_en": "Chai Nat",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 10,
            "name_th": "สระบุรี",
            "name_en": "Saraburi",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 11,
            "name_th": "ชลบุรี",
            "name_en": "Chon Buri",
            "geography_id": 5,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 12,
            "name_th": "ระยอง",
            "name_en": "Rayong",
            "geography_id": 5,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 13,
            "name_th": "จันทบุรี",
            "name_en": "Chanthaburi",
            "geography_id": 5,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 14,
            "name_th": "ตราด",
            "name_en": "Trat",
            "geography_id": 5,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 15,
            "name_th": "ฉะเชิงเทรา",
            "name_en": "Chachoengsao",
            "geography_id": 5,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 16,
            "name_th": "ปราจีนบุรี",
            "name_en": "Prachin Buri",
            "geography_id": 5,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 17,
            "name_th": "นครนายก",
            "name_en": "Nakhon Nayok",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 18,
            "name_th": "สระแก้ว",
            "name_en": "Sa Kaeo",
            "geography_id": 5,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 19,
            "name_th": "นครราชสีมา",
            "name_en": "Nakhon Ratchasima",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 20,
            "name_th": "บุรีรัมย์",
            "name_en": "Buri Ram",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 21,
            "name_th": "สุรินทร์",
            "name_en": "Surin",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 22,
            "name_th": "ศรีสะเกษ",
            "name_en": "Si Sa Ket",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 23,
            "name_th": "อุบลราชธานี",
            "name_en": "Ubon Ratchathani",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 24,
            "name_th": "ยโสธร",
            "name_en": "Yasothon",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 25,
            "name_th": "ชัยภูมิ",
            "name_en": "Chaiyaphum",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 26,
            "name_th": "อำนาจเจริญ",
            "name_en": "Amnat Charoen",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 27,
            "name_th": "หนองบัวลำภู",
            "name_en": "Nong Bua Lam Phu",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 28,
            "name_th": "ขอนแก่น",
            "name_en": "Khon Kaen",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 29,
            "name_th": "อุดรธานี",
            "name_en": "Udon Thani",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 30,
            "name_th": "เลย",
            "name_en": "Loei",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 31,
            "name_th": "หนองคาย",
            "name_en": "Nong Khai",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 32,
            "name_th": "มหาสารคาม",
            "name_en": "Maha Sarakham",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 33,
            "name_th": "ร้อยเอ็ด",
            "name_en": "Roi Et",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 34,
            "name_th": "กาฬสินธุ์",
            "name_en": "Kalasin",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 35,
            "name_th": "สกลนคร",
            "name_en": "Sakon Nakhon",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 36,
            "name_th": "นครพนม",
            "name_en": "Nakhon Phanom",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 37,
            "name_th": "มุกดาหาร",
            "name_en": "Mukdahan",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 38,
            "name_th": "เชียงใหม่",
            "name_en": "Chiang Mai",
            "geography_id": 1,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 39,
            "name_th": "ลำพูน",
            "name_en": "Lamphun",
            "geography_id": 1,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 40,
            "name_th": "ลำปาง",
            "name_en": "Lampang",
            "geography_id": 1,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 41,
            "name_th": "อุตรดิตถ์",
            "name_en": "Uttaradit",
            "geography_id": 1,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 42,
            "name_th": "แพร่",
            "name_en": "Phrae",
            "geography_id": 1,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 43,
            "name_th": "น่าน",
            "name_en": "Nan",
            "geography_id": 1,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 44,
            "name_th": "พะเยา",
            "name_en": "Phayao",
            "geography_id": 1,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 45,
            "name_th": "เชียงราย",
            "name_en": "Chiang Rai",
            "geography_id": 1,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 46,
            "name_th": "แม่ฮ่องสอน",
            "name_en": "Mae Hong Son",
            "geography_id": 1,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 47,
            "name_th": "นครสวรรค์",
            "name_en": "Nakhon Sawan",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 48,
            "name_th": "อุทัยธานี",
            "name_en": "Uthai Thani",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 49,
            "name_th": "กำแพงเพชร",
            "name_en": "Kamphaeng Phet",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 50,
            "name_th": "ตาก",
            "name_en": "Tak",
            "geography_id": 4,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 51,
            "name_th": "สุโขทัย",
            "name_en": "Sukhothai",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 52,
            "name_th": "พิษณุโลก",
            "name_en": "Phitsanulok",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 53,
            "name_th": "พิจิตร",
            "name_en": "Phichit",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 54,
            "name_th": "เพชรบูรณ์",
            "name_en": "Phetchabun",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 55,
            "name_th": "ราชบุรี",
            "name_en": "Ratchaburi",
            "geography_id": 4,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 56,
            "name_th": "กาญจนบุรี",
            "name_en": "Kanchanaburi",
            "geography_id": 4,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 57,
            "name_th": "สุพรรณบุรี",
            "name_en": "Suphan Buri",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 58,
            "name_th": "นครปฐม",
            "name_en": "Nakhon Pathom",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 59,
            "name_th": "สมุทรสาคร",
            "name_en": "Samut Sakhon",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 60,
            "name_th": "สมุทรสงคราม",
            "name_en": "Samut Songkhram",
            "geography_id": 2,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 61,
            "name_th": "เพชรบุรี",
            "name_en": "Phetchaburi",
            "geography_id": 4,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 62,
            "name_th": "ประจวบคีรีขันธ์",
            "name_en": "Prachuap Khiri Khan",
            "geography_id": 4,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 63,
            "name_th": "นครศรีธรรมราช",
            "name_en": "Nakhon Si Thammarat",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 64,
            "name_th": "กระบี่",
            "name_en": "Krabi",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 65,
            "name_th": "พังงา",
            "name_en": "Phangnga",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 66,
            "name_th": "ภูเก็ต",
            "name_en": "Phuket",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 67,
            "name_th": "สุราษฎร์ธานี",
            "name_en": "Surat Thani",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 68,
            "name_th": "ระนอง",
            "name_en": "Ranong",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 69,
            "name_th": "ชุมพร",
            "name_en": "Chumphon",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 70,
            "name_th": "สงขลา",
            "name_en": "Songkhla",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 71,
            "name_th": "สตูล",
            "name_en": "Satun",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 72,
            "name_th": "ตรัง",
            "name_en": "Trang",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 73,
            "name_th": "พัทลุง",
            "name_en": "Phatthalung",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 74,
            "name_th": "ปัตตานี",
            "name_en": "Pattani",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 75,
            "name_th": "ยะลา",
            "name_en": "Yala",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 76,
            "name_th": "นราธิวาส",
            "name_en": "Narathiwat",
            "geography_id": 6,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          },
          {
            "id": 77,
            "name_th": "บึงกาฬ",
            "name_en": "buogkan",
            "geography_id": 3,
            "created_at": "9/8/2019 03:33:09",
            "updated_at": "16/5/2022 06:31:03.648279",
            "deleted_at": null
          }
        ],
        markers: [],
        itemCCTVindex: 0,
        // markers: [{
        //     name: 'กรุงเทพ1',
        //     location: {
        //       lon: 100.58,
        //       lat: 13.761
        //     },
        //     icon: {
        //       url: iconURLbox,
        //     },
        //   },
        //   {
        //     name: 'เหนือ1',
        //     location: {
        //       lon: 100.58,
        //       lat: 16.761
        //     },
        //     icon: {
        //       url: iconURLbox,
        //     },
        //   },
        // ]
      };
    },
    methods: {
      async test(map) {
       
        if(this.ListDeviceItem!=[]){
        var locationList = [];
        for (let index = 0; index < this.markers.length; index++) {
          locationList.push(this.markers[index]['location'])
        }
        // console.log(map + 'from MAP')
        // console.log(locationList)
        var minLon = Number.MAX_VALUE;
        var maxLon = Number.MIN_VALUE;
        var minLat = Number.MAX_VALUE;
        var maxLat = Number.MIN_VALUE;

        for (var i = 0; i < locationList.length; i++) {
          var location = locationList[i];
          minLon = Math.min(minLon, location["lon"]);
          maxLon = Math.max(maxLon, location["lon"]);
          minLat = Math.min(minLat, location["lat"]);
          maxLat = Math.max(maxLat, location["lat"]);
          if(i==locationList.length-1){
            this.loadList =true;
          }
        }

        var result ={
          "minLon": minLon.toFixed(2), // You can adjust the number of decimal places
          "minLat": minLat.toFixed(2), // according to your requirements
          "maxLon": maxLon.toFixed(2),
          "maxLat": maxLat.toFixed(2)
        };
if( this.loadList == true){
       await map.bound(result);
}
      }
      },
      removeCCTVLocal() {
        localStorage.removeItem("partcctv")
      },
        callgetitemCCTV () {
        if (!localStorage.getItem("partcctv")) {
          // localStorage.setItem("partcctv")
          this.itemCCTVindex = 1;
        } else {
          this.itemCCTVindex = localStorage.getItem("partcctv")
        }
        // try{
        //   this.itemCCTVindex=localStorage.getItem("partcctv")
        // }catch(e){
        //   console.log(e)
        // }

      },
      navigateToDevice() {
        localStorage.setItem("navigate", true);
      },
      async callApiMap() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/devices/map", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
          },
        });
        var data = await response.json();
        this.ListDeviceItem = await data;
        // console.log(data);
        for (let index = 0; index < this.ListDeviceItem.length; index++) {
          var useURL;
          // console.log(this.ListDeviceItem[index].Namebox.status)
          if (this.ListDeviceItem[index].Namebox.status == false) {
            useURL = iconURLboxError;
            console.log(this.ListDeviceItem)
          } else {
            useURL = iconURLbox;
          }
          // const element = array[index];
          this.markers.push({
            name: this.ListDeviceItem[index].Namebox.name,
            location: {
              lat: this.ListDeviceItem[index].Namebox.latitude,
              lon: this.ListDeviceItem[index].Namebox.longitude
            },
            icon: {
              url: useURL,
            },
            id: this.ListDeviceItem[index].Namebox.id,
            // part:this.ListDeviceItem[index].Namebox.part,
            status: this.ListDeviceItem[index].Namebox.status,
            Province: this.ListDeviceItem[index].Namebox.Province,
            ZipCode: this.ListDeviceItem[index].Namebox.ZipCode,
            zone: this.ListDeviceItem[index].Namebox.name,
            device: this.ListDeviceItem[index].device,
            popup: {
              html: '<div style="background: #eeeeff;">popup</div>',
            }
          })
          // markers=this.markers
          // console.log(this.markers);
          // console.log("test")
          // console.log(this.ListDeviceItem[index].name);
          // console.log(this.ListDeviceItem[index].latitude);
        }
        console.log(this.markers);
        console.log("test")
      },
      callLocalStorageLocation: function () {
        setInterval(() => {
          try {
            let local = localStorage.getItem("locations");
            let arr = local.split(",");
            let local_id = localStorage.getItem("locations");
            let arr_id = local_id.split(",");
            this.localStorageParamiter.id = arr_id;
            this.localStorageParamiter.location = arr;
            this.localStorageParamiter.length = arr.length;
          } catch (error) {
            // console.log(error)
          }
        }, 2000);
      },
      removeLocalStorage: function () {
        localStorage.removeItem("locations");
        localStorage.removeItem("locationsID");
        this.localStorageParamiter.location = [];
        this.localStorageParamiter.length = 0;
      },
      async fillterDeviceByAdmin() {
        if (this.fillterAll.Status == "Online") {
          this.status = true;
        } else if (this.fillterAll.Status == "Offline") {
          this.status = false;
        } else {
          this.status = null;
        }

        const response = await fetch(process.env.VUE_APP_API_URL + "/api/device/allfilter", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
          },
          body: JSON.stringify({
            nameDevice: this.fillterAll.nameDevice,
            Province: this.fillterAll.Province,
            ZipCode: parseInt(this.fillterAll.ZipCode),
            Status: this.status,
            Type: this.fillterAll.Type,
            zoneId: [this.fillterAll.zoneId]
          }),
        });
        var data = await response.json();
        this.ListDeviceItem = await data;
        console.log(data);
        for (let index = 0; index < this.ListDeviceItem.length; index++) {
          // const element = array[index];
          this.markers.push({
            name: this.ListDeviceItem[index].name,
            location: {
              lat: this.ListDeviceItem[index].latitude,
              lon: this.ListDeviceItem[index].longitude
            },
            icon: {
              url: iconURLbox,
            },
            id: this.ListDeviceItem[index].id,
            part: this.ListDeviceItem[index].part,
          })
          // console.log(this.ListDeviceItem[index].name);
          // console.log(this.ListDeviceItem[index].latitude);
        }
        console.log(this.markers);
        console.log("tes")
      },
      async getAreaAll() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/zones/all", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
          },
        });
        var data = await response.json();
        this.ListAreaItem = await data;
        console.log(data);
      },
      readRole() {
        var arearole = localStorage.getItem("adminrole");
        if (arearole == "ALL") {
          this.roleArea = "ทั้งหมด";
        } else {
          this.roleArea = arearole;
        }
      }
    },
    mounted: function () {
   
      this.timer = setInterval(() => {
        this.callLocalStorageLocation()
        this.callgetitemCCTV();
        console.log("on 1s")
      }, 1000)
    },


    created() {
      checktokenAPI.check();
      this.readRole();
      this.callApiMap();
    },
    beforeMount(){
      // this.callApiMap();
    },
    beforeUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.timer);
  },
  };
</script>

<style>
  :root {
    --base-grid: 8px;
    --colour-white: #fff;
    --colour-black: #1a1a1a;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
  }


  html {
    margin: 0;
    padding: 0;
    /* background-image: linear-gradient(-170deg, #064997 20%, #105ba7); */
    background-position: 100%;
  }

  /* body {
  background-image: linear-gradient(270deg,#2b67ac 3px,transparent 0),linear-gradient(#2b67ac 3px,transparent 0),linear-gradient(270deg,rgba(43,103,172,.4) 1px,transparent 0),linear-gradient(#2b67ac 1px,transparent 0),linear-gradient(270deg,rgba(43,103,172,.4) 1px,transparent 0),linear-gradient(#2b67ac 1px,transparent 0);
  background-size: 112px 112px,112px 112px,56px 56px,56px 56px,28px 28px,28px 28px;
  font-family: Josefin Sans, sans-serif;
    margin: 0;
  padding: 0;
} */

  .links {
    margin-left: 0px;
    /* padding-bottom: 5px; */
    --link-size: calc(var(--base-grid)*8);
    color: var(--colour-black);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* min-height: 10vh; */
  }

  .links__list {
    position: relative;
    list-style: none;
  }

  .links__item {
    width: var(--link-size);
    height: var(--link-size);
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 30px;
    margin-left: 23px;
    --angle: calc(360deg/var(--item-total));
    --rotation: calc(90deg + var(--angle)*var(--item-count));
    transform: rotate(var(--rotation)) translate(calc(var(--link-size) + var(--base-grid)*2)) rotate(calc(var(--rotation)*-1));
  }

  .links__link {
    opacity: 0;
    animation: on-load .3s ease-in-out forwards;
    animation-delay: calc(var(--item-count)*150ms);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    background-color: var(--colour-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }

  .links__icon {
    width: calc(var(--base-grid)*5);
    height: calc(var(--base-grid)*5);
    transition: all .3s ease-in-out;
    fill: var(--colour-black);
  }

  .links__text {
    position: absolute;
    color: black;
    font-weight: 600;
    width: 100%;
    left: 0;
    text-align: center;
    height: calc(var(--base-grid)*3);
    font-size: calc(var(--base-grid)*1.4);
    display: none;
    bottom: calc(var(--base-grid)*5.5);
    animation: text .5s ease-in-out forwards;
  }

  .links__link:after {
    content: "";
    background-color: transparent;
    width: var(--link-size);
    height: var(--link-size);
    border: 2px dashed var(--colour-white);
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .3s cubic-bezier(.53, -.67, .73, .74);
    transform: none;
    opacity: 0;
  }

  .links__link:hover .links__icon {
    transition: all .3s ease-in-out;
    transform: translateY(calc(var(--base-grid)*-1));
  }

  .links__link:hover .links__text {
    display: block;
  }

  .links__link:hover:after {
    transition: all .3s cubic-bezier(.37, .74, .15, 1.65);
    transform: scale(1.1);
    opacity: 1;
  }

  @keyframes on-load {
    0% {
      opacity: 0;
      transform: scale(.3);
    }

    70% {
      opacity: .7;
      transform: scale(1.1);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @media only screen and (max-width: 500px) {
    .testcolor {
      background-color: blue;
      /* display: none; */
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 1500px) {
    .testcolor {
      background-color: red;
      display: none;
    }
  }




  @keyframes text {
    0% {
      opacity: 0;
      transform: scale(0.3) translateY(0);
    }

    100% {
      opacity: 1;
      transform: scale(1) translateY(calc(var(--base-grid)*6));
    }
  }

  /* CSS */
  .button-3 {
    width: 100%;
    appearance: none;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 6px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
  }

  .button-3:focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }

  .button-3:hover {
    background-color: #2c974b;
  }

  .button-3:focus {
    box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
    outline: none;
  }

  .button-3:disabled {
    background-color: #94d3a2;
    border-color: rgba(27, 31, 35, .1);
    color: rgba(255, 255, 255, .8);
    cursor: default;
  }

  .button-3:active {
    background-color: #298e46;
    box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
  }

  .dropdown-menu {
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 150px);
  }

  table {
    font-family: arial, sans-serif;
    /* border-collapse: collapse; */
    width: 100%;
  }

  td,
  th {
    /* border: 1px solid #dddddd; */
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
</style>