<template>

  <body>
    <section class="">
      <div style="background-color: hsl(0, 0%, 96%);height: 100vh;">
        <div style="height: 150px;">

        </div>
        <div class=" text-center text-lg-start">
          <div class="container">
            <div class="row justify-content-center">

              <div class="col-xl-5">

                <div class="card shadow-lg" style="width: 100%;">
                  <div class="card-body px-5" style="width: 100%;">
                      <img src="../../assets/logo.png" style="width: 100%;max-width: 350px;">
                    <h5 class="mt-2 mb-3 text-center" style="width: 100%;">
                      เข้าสู่ระบบ
                    </h5>
                    <!-- Email input -->
                    <div class="form-outline mb-3">
                      <input type="text" v-model="Web_username" class="form-control" />
                      <label class="form-label" for="form3Example3">Username</label>
                    </div>
                    <!-- Password input -->
                    <div class="form-outline ">
                      <input type="password" v-model="Web_password" class="form-control" />
                      <label class="form-label">Password</label>
                    </div>
                    <div class="text-end pb-4 me-2">
                        <router-link to="/forgotpassword" style="text-decoration: none;">
                          ลืมรหัสผ่าน
                        </router-link>
                      </div>
                    <div class="text-center mb-2 text-danger">{{ ErrorMessage }}</div>
                    <!-- Submit button -->
                    <div class="text-center">
                      <button type="submit" class="btn btn-warning btn-block mb-4 text-white" @click="loginUser()"
                        style="width: 200px;">
                        เข้าสู่ระบบ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</template>

<script>
  export default {
    data() {
      return {
        Web_username: '',
        Web_password: '',
        data: '',
        ErrorMessage: ''
      };
    },
    methods: {
      checkStatusLogin() {
        try {
          var adminStatus = localStorage.getItem("token_admin");
          var adminALL_check = localStorage.getItem("adminrole_state");
          if (adminStatus !== null) {
            if(adminALL_check=="true"){
              this.$router.replace({
              path: "/supperadmin/landing"
            });
            }else{
              this.$router.replace({
              path: "/zoneadmin/landing"
            });
            }
          
          }
        } catch (error) {
          console.log(error);
        }
        try {
          var userStatus = localStorage.getItem("token_user");
          if (userStatus !== null) {
            this.$router.replace({
              path: "/user/map"
            });
            console.log(userStatus);
          }
        } catch (error) {
          console.log(error);
        }
      },
      async loginUser() {
        const response = await fetch(process.env.VUE_APP_API_URL+"/api/auth/signin", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: this.Web_username,
            password: this.Web_password
          }),
        });
        console.log(response);
        var data = await response.json();
        if (data.accessToken == null) {
          this.ErrorMessage = data.message;
        } else {
          this.ErrorMessage = "";
          if (data.roles == "ROLE_admin") {
            localStorage.setItem("token_admin", data.accessToken);
            localStorage.setItem("username", data.username);
            localStorage.removeItem("token_user");
            localStorage.setItem("verify_time", Date.now());
            localStorage.setItem("adminrole", data.zones) 
            localStorage.setItem("adminrole_state", data.allzone)
            if (data.allzone==true) {
              this.$router.replace({
              path: "/supperadmin/landing"
            });
            } else {
              this.$router.replace({
              path: "/zoneadmin/landing"
            });
            }
          } else if (data.roles == "ROLE_user") {
            this.$router.replace({
              path: "/user/map"
            });
            localStorage.setItem("token_user", data.accessToken);
            localStorage.removeItem("token_admin");
          }
        }
        console.log(data);
        this.data = data;
      },
    },
    created() {
      this.checkStatusLogin();
    },
  };
</script>