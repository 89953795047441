<template>
    <!-- Modal Adddevice -->
    <div class="modal fade" id="adddeviceModal" tabindex="-1" aria-labelledby="adddeviceModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="adddeviceModalLabel">+ สร้างกล่อง</h5>
                </div>
                <div class="modal-body" v-if="timeoutLoginState==false">
                    <div class="">
                        <div>
                            <span>ชื่อกล่อง : </span><input v-model="addnewdevicebox.name" placeholder="ชื่ออุปกรณ์"
                                style="width: 100%; height: 35px;" />
                        </div>
                        <div class="mt-2">
                            <div>พื้นที่:</div>
                            <select v-if="roleArea=='ทั้งหมด'" v-model="addnewdevicebox.area"
                                style="width: 100%; height: 35px;">
                                {{  ListAreaItem }}
                                <option disabled value="">เลือกพื้นที่ </option>
                                <option class="" v-for="(itemArea, i) in ListAreaItem" :key="i">
                                    <div v-if="itemArea.name=='ALL'" disabled value=""></div>
                                    <div v-else class="dropdown-item" href="#">{{ itemArea.name }}</div>
                                </option>
                            </select>
                            <select v-else v-model="addnewdevicebox.area" style="width: 100%; height: 35px;">
                                <option disabled value="">{{ roleArea }}</option>
                            </select>
                        </div>
                        <div class="mt-2">
                            <span>จังหวัด: </span>
                            <input type="text" v-model="searchQuery" placeholder="ค้นหาจังหวัด">
                            <select class="ms-1 col-5" v-model="addnewdevicebox.province">
                                <option disabled value="เลือกจังหวัด">เลือกจังหวัด</option>
                                <option class="" v-for="(itemProvince, i) in filteredProvinces()" :key="i">
                                    {{ itemProvince.name_th }}
                                </option>
                            </select>

                        </div>

                        <div>
                            <span>รหัสไปรษณีย์ : </span><input v-model="addnewdevicebox.postcode"
                                placeholder="รหัสไปรษณีย์" style="width: 100%; height: 35px;" />
                        </div>
                        <div class="text-end mt-2">
                            <span>ตำแหน่งที่ตั้ง : </span>
                            <input v-model="addnewdevicebox.lat" placeholder="latitude"
                                style="width: 30%; height: 35px;" />
                            &nbsp;
                            <input v-model="addnewdevicebox.long" placeholder="longitude"
                                style="width: 30%; height: 35px;" />
                        </div>


                    </div>
                </div>
                <div class="modal-body" v-if="timeoutLoginState==true">
                    <div class="card-body px-5" style="width: 100%;">
                        <img src="../../assets/logo.png" alt="" style="max-width: 300px;">
                        <h5 class="mt-2 mb-3 text-center" style="width: 100%;">
                            เข้าสู่ระบบอีกครั้ง
                        </h5>
                        <!-- Email input -->
                        <div class="form-outline mb-4">
                            <input type="text" v-model="insideLogin.username" class="form-control" />
                            <label class="form-label" for="form3Example3">Username</label>
                        </div>
                        <!-- Password input -->
                        <div class="form-outline mb-4">
                            <input type="password" v-model="insideLogin.password" class="form-control" />
                            <label class="form-label">Password</label>
                        </div>
                        <div class="text-center mb-2 text-danger">{{ insideLogin.ErrorMessage }}</div>
                        <!-- Submit button -->
                        <div class="text-center">
                            <button type="submit" class="btn btn-warning btn-block mb-4 text-white"
                                @click="reloginWhenTimeOut()" style="width: 200px;">
                                เข้าสู่ระบบ
                            </button>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button
                        v-if="timeoutLoginState==false&&addnewdevicebox.name!=''&& addnewdevicebox.area!=''&& addnewdevicebox.province!=''&& addnewdevicebox.postcode!=''&& addnewdevicebox.lat!=''&& addnewdevicebox.long!=''"
                        type="button" class="btn btn-warning" @click="addDeviceByAdmin()" data-bs-dismiss="modal">+
                        สร้าง </button>
                    <button v-else type="button" class="btn btn-light">
                        +กรุณากรอกข้อมูลให้ครบ</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal delete device -->
    <div class="modal fade" id="removedeviceModal" tabindex="-1" aria-labelledby="removedeviceModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="removedeviceModalLabel">ลบอุปกรณ์</h5>
                </div>
                <div class="modal-body" v-if="timeoutLoginState==false">
                    <div class="text-center">ยืนยันการลบอุปกรณ์</div>
                </div>
                <div class="modal-body" v-if="timeoutLoginState==true">
                    <div class="card-body px-5" style="width: 100%;">
                        <img src="../../assets/logo.png" alt="" style="width: 350px;">
                        <!-- 2 column grid layout with text inputs for the first and last names -->
                        <h5 class="mt-2 mb-3 text-center" style="width: 100%;">
                            เข้าสู่ระบบอีกครั้ง
                        </h5>

                        <!-- Email input -->
                        <div class="form-outline mb-4">
                            <input type="text" v-model="insideLogin.username" class="form-control" />
                            <label class="form-label" for="form3Example3">Username</label>
                        </div>

                        <!-- Password input -->
                        <div class="form-outline mb-4">
                            <input type="password" v-model="insideLogin.password" class="form-control" />
                            <label class="form-label">Password</label>
                        </div>
                        <div class="text-center mb-2 text-danger">{{ insideLogin.ErrorMessage }}</div>
                        <!-- Submit button -->
                        <div class="text-center">
                            <button type="submit" class="btn btn-warning btn-block mb-4 text-white"
                                @click="reloginWhenTimeOut()" style="width: 200px;">
                                เข้าสู่ระบบ
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button v-if="timeoutLoginState==false" type="button" class="btn btn-warning"
                        @click="deleteDeviceByAdmin()" data-bs-dismiss="modal">ยืนยัน</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal edit device -->
    <div class="modal fade" id="editdeviceModal" tabindex="-1" aria-labelledby="editdeviceModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editdeviceModalLabel">แก้ไขอุปกรณ์</h5>
                </div>
                <div class="modal-body" v-if="timeoutLoginState==false">
                    <div class="">
                        <div>
                            <span>ชื่อกล่อง : </span><input v-model="CurrentItem.name" placeholder="ชื่ออุปกรณ์"
                                style="width: 100%; height: 35px;" />
                        </div>
                        <!-- <div class="mt-2">
                            <div>พื้นที่:</div>
                            <select v-model="CurrentItem.zone[0]" style="width: 100%; height: 35px;">
                                <option disabled value="">เลือกพื้นที่</option>
                                <option class="" v-for="(itemArea, i) in ListAreaItem" :key="i">
                                    <div class="dropdown-item" href="#">{{ itemArea.name }}</div>
                                </option>
                            </select>
                        </div> -->

                        <div class="mt-2">
                            <div>พื้นที่: {{ CurrentItem.zone[0] }}</div>
                            <!-- <select v-if="roleArea=='ทั้งหมด'" v-model="addnewdevicebox.area"
                                style="width: 100%; height: 35px;">
                                {{  ListAreaItem }}
                                <option disabled value="">เลือกพื้นที่ </option>
                                <option class="" v-for="(itemArea, i) in ListAreaItem" :key="i">
                                    <div v-if="itemArea.name=='ALL'" disabled value=""></div>
                                    <div v-else class="dropdown-item" href="#">{{ itemArea.name }}</div>
                                </option>
                            </select>
                            <select v-else v-model="CurrentItem.zone[0]" style="width: 100%; height: 35px;">
                                <option disabled value="">{{ roleArea }}</option>
                                <option disabled >{{ roleArea }}</option>
                            </select> -->
                        </div>

                        <div>
                            <span>จังหวัด: </span>

                            <input class="mt-2" type="text" v-model="searchQuery" placeholder="ค้นหาจังหวัด">
                            <select class="ms-1 col-5" v-model="CurrentItem.Province">
                                <option disabled value="เลือกจังหวัด">เลือกจังหวัด</option>
                                <option class="" v-for="(itemProvince, i) in filteredProvinces()" :key="i">
                                    {{ itemProvince.name_th }}
                                </option>
                            </select>
                        </div>
                        <div>
                            <span>รหัสไปรษณีย์ : </span><input v-model="CurrentItem.ZipCode" placeholder="รหัสไปรษณีย์"
                                style="width: 100%; height: 35px;" />
                        </div>
                        <div class="text-end mt-2">
                            <span>ตำแหน่งที่ตั้ง : </span>
                            <input v-model="CurrentItem.latitude" placeholder="latitude"
                                style="width: 30%; height: 35px;" />
                            &nbsp;
                            <input v-model="CurrentItem.longitude" placeholder="longitude"
                                style="width: 30%; height: 35px;" />
                        </div>
                    </div>
                </div>
                <div class="modal-body" v-if="timeoutLoginState==true">
                    <div class="card-body px-5" style="width: 100%;">
                        <img src="../../assets/logo.png" alt="" style="width: 350px;">
                        <h5 class="mt-2 mb-3 text-center" style="width: 100%;">
                            เข้าสู่ระบบอีกครั้ง
                        </h5>

                        <div class="form-outline mb-4">
                            <input type="text" v-model="insideLogin.username" class="form-control" />
                            <label class="form-label" for="form3Example3">Username</label>
                        </div>

                        <div class="form-outline mb-4">
                            <input type="password" v-model="insideLogin.password" class="form-control" />
                            <label class="form-label">Password</label>
                        </div>
                        <div class="text-center mb-2 text-danger">{{ insideLogin.ErrorMessage }}</div>

                        <div class="text-center">
                            <button type="submit" class="btn btn-warning btn-block mb-4 text-white"
                                @click="reloginWhenTimeOut()" style="width: 200px;">
                                เข้าสู่ระบบ
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button v-if="timeoutLoginState==false" type="button" class="btn btn-warning"
                        @click="editDeviceByAdmin()" data-bs-dismiss="modal">แก้ไขอุปกรณ์</button>
                </div>
            </div>
        </div>
    </div>


    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">
                <img src="../../assets/logo.png" style="width: 250px;" alt="">
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <router-link to="/zoneadmin/map" style="text-decoration: none;">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="#">อุปกรณ์บนแผนที่</a>
                        </li>
                    </router-link>
                    <router-link v-if="roleArea=='ทั้งหมด'" to="/zoneadmin/area" style="text-decoration: none;">
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#">เขตพื้นที่</a>
                        </li>
                    </router-link>
                    <router-link to="/zoneadmin/adduser" style="text-decoration: none;">
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#">ผู้ใช้งาน</a>
                        </li>
                    </router-link>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            อุปกรณ์
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li>
                                <router-link to="/zoneadmin/detailnamebox" style="text-decoration: none;"><a
                                        class="dropdown-item text-warning" href="">รายละเอียดกล่อง</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/zoneadmin/detaildevice" style="text-decoration: none;"><a
                                        class="dropdown-item " href="">รายละเอียดที่ตั้งอุปกรณ์</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/zoneadmin/detailhardware" style="text-decoration: none;"><a
                                        class="dropdown-item" href="#">รายละเอียดรุ่นอุปกรณ์</a></router-link>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li>
                <router-link to="/zoneadmin/statusdevice" style="text-decoration: none;"><a class="dropdown-item"
                    href="#">รายงานอุปกรณ์ออฟไลน์</a></router-link>
              </li>
                            <!-- <li><a class="dropdown-item" href="#">รายงานอุปกรณ์ออฟไลน์</a></li> -->
                        </ul>
                    </li>
                </ul>
                <form class="d-flex">
                    <div class="col-12 text-end">
                        <button class="btn btn-warning ms-2" type="button" data-bs-toggle="modal"
                            data-bs-target="#adddeviceModal" @click="timeOut(),gethardwareByAdmin()">+
                            สร้างกล่อง</button>
                    </div>
                </form>
            </div>
        </div>
    </nav>

    <body>
        <div style="height:100px;"></div>
        <div>
            <!-- <button @click="showPopupAfterDelay">Show Popup</button> -->
            <div v-if="showPopup" class="popup rounded ">
                <font-awesome-icon icon="fa-solid fa-circle-check" style="font-size: 80px;color:#32CD32;" />
                <p class="text-center mt-2">สำเร็จ</p>
            </div>
        </div>

        <div class="row mt-2 ms-1" style="overflow-x:auto;">
            <!-- <div style="display:none;">{{ getDeviceAll() }}</div> -->
            <div style="display: none;">{{ removeNavigate() }}</div>
            <table class="mt-3">
                <tr>
                    <th style="min-width: 180px;">ชื่อกล่อง</th>
                    <th style="min-width: 120px;">เขตพิ้นที่</th>
                    <th style="min-width: 120px;">จังหวัด</th>
                    <th style="min-width: 120px;">รหัสไปรษณีย์</th>
                    <th style="min-width: 100px;">สถานะ</th>
                    <th style="min-width: 100px;">ตำแหน่งที่ตั้ง</th>
                    <th class="text-center" style="min-width: 100px;">แก้ไข</th>
                </tr>

                <tr v-for="(DeviceItem, i) in ListDeviceAllItem" :key="i">
                    <td>{{ DeviceItem.name }}</td>
                    <td>{{ DeviceItem.zone[0] }}</td>
                    <td>{{ DeviceItem.Province}}</td>
                    <td>{{ DeviceItem.ZipCode}}</td>
                    <!-- <td>{{ DeviceItem.status}}</td> -->
                    <td v-if="DeviceItem.status==true">Online</td>
                    <td v-else>Offline</td>
                    <td>{{ DeviceItem.latitude}}, {{ DeviceItem.longitude }}</td>
                    <td class="text-start"> <span>
                            <font-awesome-icon type="button" data-bs-toggle="modal" data-bs-target="#editdeviceModal"
                                style="color: skyblue;" icon="fa-solid fa-pen"
                                @click="timeOut(),setCurrentId(DeviceItem)" />
                            <font-awesome-icon class="ms-3" type="button" data-bs-toggle="modal"
                                data-bs-target="#removedeviceModal" style="color: #FFBBAC;"
                                icon="fa-solid fa-box-archive" @click="timeOut(),setCurrentId(DeviceItem)" /></span>
                    </td>

                </tr>

            </table>
        </div>
        <br>
    </body>

</template>
<script>
import checktokenAPI from "../connectBackend/checkTokenFirst";
    import CheckTimeService from '../timeVerify/timeoutVerify'
    export default {
        data() {
            return {
                selectedCCTVOrName: false,
                roleArea: '',
                CurrentItem: {
                    nofild: '',
                    id: null,
                    name: '',
                    zone: {
                        name: ''
                    },
                    Province: '',
                    ZipCode: null,
                    status: '',
                    type: '',
                    LiveUrl: '',
                    latitude: null,
                    longitude: null
                },

                timeoutLoginState: false,
                showPopup: false,
                selectedTEXTorCCTV: 1,
                postcode: '',
                selected: 'ที่ตั้งอุปกรณ์หมด',
                selectedProvince: '',
                ListDeviceAllItem: [],
                ListDeviceProvinceItem: [],
                ListDevicePostcodeItem: [],
                ListDevicePinItem: [],
                ListAreaItem: [],
                listHardware: [],
                addnewdevicebox: {
                    name: '',
                    area: '',
                    province: '',
                    postcode: '',
                    type: '',
                    url: '',
                    lat: '',
                    long: '',
                    hardware: '',
                    RN: '',
                    location: ''
                },
                idOfDevicelist: [],
                nameDevicelist: [],
                test: [],
                insideLogin: {
                    username: '',
                    password: '',
                    ErrorMessage: ''
                },
                searchQuery: '',
                provinces: [{
                        "id": 1,
                        "name_th": "กรุงเทพ",
                        "name_en": "Bangkok",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 2,
                        "name_th": "สมุทรปราการ",
                        "name_en": "Samut Prakan",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 3,
                        "name_th": "นนทบุรี",
                        "name_en": "Nonthaburi",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 4,
                        "name_th": "ปทุมธานี",
                        "name_en": "Pathum Thani",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 5,
                        "name_th": "พระนครศรีอยุธยา",
                        "name_en": "Phra Nakhon Si Ayutthaya",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 6,
                        "name_th": "อ่างทอง",
                        "name_en": "Ang Thong",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 7,
                        "name_th": "ลพบุรี",
                        "name_en": "Loburi",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 8,
                        "name_th": "สิงห์บุรี",
                        "name_en": "Sing Buri",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 9,
                        "name_th": "ชัยนาท",
                        "name_en": "Chai Nat",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 10,
                        "name_th": "สระบุรี",
                        "name_en": "Saraburi",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 11,
                        "name_th": "ชลบุรี",
                        "name_en": "Chon Buri",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 12,
                        "name_th": "ระยอง",
                        "name_en": "Rayong",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 13,
                        "name_th": "จันทบุรี",
                        "name_en": "Chanthaburi",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 14,
                        "name_th": "ตราด",
                        "name_en": "Trat",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 15,
                        "name_th": "ฉะเชิงเทรา",
                        "name_en": "Chachoengsao",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 16,
                        "name_th": "ปราจีนบุรี",
                        "name_en": "Prachin Buri",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 17,
                        "name_th": "นครนายก",
                        "name_en": "Nakhon Nayok",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 18,
                        "name_th": "สระแก้ว",
                        "name_en": "Sa Kaeo",
                        "geography_id": 5,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 19,
                        "name_th": "นครราชสีมา",
                        "name_en": "Nakhon Ratchasima",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 20,
                        "name_th": "บุรีรัมย์",
                        "name_en": "Buri Ram",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 21,
                        "name_th": "สุรินทร์",
                        "name_en": "Surin",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 22,
                        "name_th": "ศรีสะเกษ",
                        "name_en": "Si Sa Ket",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 23,
                        "name_th": "อุบลราชธานี",
                        "name_en": "Ubon Ratchathani",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 24,
                        "name_th": "ยโสธร",
                        "name_en": "Yasothon",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 25,
                        "name_th": "ชัยภูมิ",
                        "name_en": "Chaiyaphum",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 26,
                        "name_th": "อำนาจเจริญ",
                        "name_en": "Amnat Charoen",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 27,
                        "name_th": "หนองบัวลำภู",
                        "name_en": "Nong Bua Lam Phu",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 28,
                        "name_th": "ขอนแก่น",
                        "name_en": "Khon Kaen",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 29,
                        "name_th": "อุดรธานี",
                        "name_en": "Udon Thani",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 30,
                        "name_th": "เลย",
                        "name_en": "Loei",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 31,
                        "name_th": "หนองคาย",
                        "name_en": "Nong Khai",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 32,
                        "name_th": "มหาสารคาม",
                        "name_en": "Maha Sarakham",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 33,
                        "name_th": "ร้อยเอ็ด",
                        "name_en": "Roi Et",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 34,
                        "name_th": "กาฬสินธุ์",
                        "name_en": "Kalasin",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 35,
                        "name_th": "สกลนคร",
                        "name_en": "Sakon Nakhon",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 36,
                        "name_th": "นครพนม",
                        "name_en": "Nakhon Phanom",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 37,
                        "name_th": "มุกดาหาร",
                        "name_en": "Mukdahan",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 38,
                        "name_th": "เชียงใหม่",
                        "name_en": "Chiang Mai",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 39,
                        "name_th": "ลำพูน",
                        "name_en": "Lamphun",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 40,
                        "name_th": "ลำปาง",
                        "name_en": "Lampang",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 41,
                        "name_th": "อุตรดิตถ์",
                        "name_en": "Uttaradit",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 42,
                        "name_th": "แพร่",
                        "name_en": "Phrae",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 43,
                        "name_th": "น่าน",
                        "name_en": "Nan",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 44,
                        "name_th": "พะเยา",
                        "name_en": "Phayao",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 45,
                        "name_th": "เชียงราย",
                        "name_en": "Chiang Rai",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 46,
                        "name_th": "แม่ฮ่องสอน",
                        "name_en": "Mae Hong Son",
                        "geography_id": 1,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 47,
                        "name_th": "นครสวรรค์",
                        "name_en": "Nakhon Sawan",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 48,
                        "name_th": "อุทัยธานี",
                        "name_en": "Uthai Thani",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 49,
                        "name_th": "กำแพงเพชร",
                        "name_en": "Kamphaeng Phet",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 50,
                        "name_th": "ตาก",
                        "name_en": "Tak",
                        "geography_id": 4,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 51,
                        "name_th": "สุโขทัย",
                        "name_en": "Sukhothai",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 52,
                        "name_th": "พิษณุโลก",
                        "name_en": "Phitsanulok",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 53,
                        "name_th": "พิจิตร",
                        "name_en": "Phichit",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 54,
                        "name_th": "เพชรบูรณ์",
                        "name_en": "Phetchabun",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 55,
                        "name_th": "ราชบุรี",
                        "name_en": "Ratchaburi",
                        "geography_id": 4,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 56,
                        "name_th": "กาญจนบุรี",
                        "name_en": "Kanchanaburi",
                        "geography_id": 4,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 57,
                        "name_th": "สุพรรณบุรี",
                        "name_en": "Suphan Buri",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 58,
                        "name_th": "นครปฐม",
                        "name_en": "Nakhon Pathom",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 59,
                        "name_th": "สมุทรสาคร",
                        "name_en": "Samut Sakhon",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 60,
                        "name_th": "สมุทรสงคราม",
                        "name_en": "Samut Songkhram",
                        "geography_id": 2,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 61,
                        "name_th": "เพชรบุรี",
                        "name_en": "Phetchaburi",
                        "geography_id": 4,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 62,
                        "name_th": "ประจวบคีรีขันธ์",
                        "name_en": "Prachuap Khiri Khan",
                        "geography_id": 4,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 63,
                        "name_th": "นครศรีธรรมราช",
                        "name_en": "Nakhon Si Thammarat",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 64,
                        "name_th": "กระบี่",
                        "name_en": "Krabi",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 65,
                        "name_th": "พังงา",
                        "name_en": "Phangnga",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 66,
                        "name_th": "ภูเก็ต",
                        "name_en": "Phuket",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 67,
                        "name_th": "สุราษฎร์ธานี",
                        "name_en": "Surat Thani",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 68,
                        "name_th": "ระนอง",
                        "name_en": "Ranong",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 69,
                        "name_th": "ชุมพร",
                        "name_en": "Chumphon",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 70,
                        "name_th": "สงขลา",
                        "name_en": "Songkhla",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 71,
                        "name_th": "สตูล",
                        "name_en": "Satun",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 72,
                        "name_th": "ตรัง",
                        "name_en": "Trang",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 73,
                        "name_th": "พัทลุง",
                        "name_en": "Phatthalung",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 74,
                        "name_th": "ปัตตานี",
                        "name_en": "Pattani",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 75,
                        "name_th": "ยะลา",
                        "name_en": "Yala",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 76,
                        "name_th": "นราธิวาส",
                        "name_en": "Narathiwat",
                        "geography_id": 6,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    },
                    {
                        "id": 77,
                        "name_th": "บึงกาฬ",
                        "name_en": "buogkan",
                        "geography_id": 3,
                        "created_at": "9/8/2019 03:33:09",
                        "updated_at": "16/5/2022 06:31:03.648279",
                        "deleted_at": null
                    }
                ],
            };
        },
        methods: {
            filteredProvinces() {
                if (!this.searchQuery) {
                    return this.provinces;
                }
                const search = this.searchQuery.toLowerCase();
                return this.provinces.filter(item => item.name_th.toLowerCase().includes(search));
            },

            navigateToDeviceRead() {
                var navigate = localStorage.getItem("navigate");
                if (navigate === "true") {
                    this.selected = 'ที่ตั้งอุปกรณ์ตามการเลือก Map';
                    this.getDeviceByID();
                    // this.test=[localStorage.getItem("locationsID")]
                    //  localStorage.getItem("navigate","");
                }
            },
            removeNavigate() {
                localStorage.removeItem("navigate");
            },
            setCurrentId(itemNow) {
                this.CurrentItem = itemNow;
            },
            async getDeviceByID() {
                var str = await localStorage.getItem("locationsID");
                var arr = await str.split(",").map(Number);
                this.idOfDevicelist = arr;
                var strName = await localStorage.getItem("locations");
                var arrName = await strName.split(",");
                this.idOfDevicelist = arr;
                this.nameDevicelist = arrName;
                const response = await fetch(process.env.VUE_APP_API_URL + "/api/device/ids", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    },
                    body: JSON.stringify({
                        id: arr
                    }),
                });
                var data = await response.json();
                console.log(data.status);
                this.ListDevicePinItem = data;

            },
            timeOut() {
                var statusTimeout = CheckTimeService.checkTimeOut();
                this.timeoutLoginState = statusTimeout;
            },
            async addDeviceByAdmin() {
                const response = await fetch(process.env.VUE_APP_API_URL + "/api/namebox/add", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    },
                    body: JSON.stringify({
                        nameBox: this.addnewdevicebox.name,
                        Province: this.addnewdevicebox.province,
                        ZipCode: this.addnewdevicebox.postcode,
                        latitude: this.addnewdevicebox.lat,
                        longitude: this.addnewdevicebox.long,
                        zoneId: [this.addnewdevicebox.area],
                        Status: true,
                        location: this.addnewdevicebox.location
                    }),
                });
                var data = await response;
                console.log(data.status);
                if (data.status == 200) {
                    this.showPopupAfterDelay();
                    this.getDeviceAll();
                }
            },
            async editDeviceByAdmin() {
                const response = await fetch(process.env.VUE_APP_API_URL + "/api/namebox/update", {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    },
                    body: JSON.stringify({
                        id: this.CurrentItem.id,
                        nameBox: this.CurrentItem.name,
                        Province: this.CurrentItem.Province,
                        ZipCode: this.CurrentItem.ZipCode,
                        status: this.CurrentItem.status,
                        // Type: this.CurrentItem.type,
                        // LiveUrl: this.CurrentItem.LiveUrl,
                        latitude: this.CurrentItem.latitude,
                        longitude: this.CurrentItem.longitude,
                        zoneId: this.CurrentItem.zone[0],
                        // RN: this.CurrentItem.RN,
                        // hardwareId: [this.CurrentItem.hardware],
                        // location: this.CurrentItem.location

                    }),
                });
                console.log("test")
                var data = await response;
                // console.log(this.CurrentItem.zone[0]);
                console.log(data.status);
                if (data.status == 200) {
                    this.showPopupAfterDelay();
                    this.getDeviceAll();
                }
            },
            async deleteDeviceByAdmin() {
                const response = await fetch(process.env.VUE_APP_API_URL + "/api/namebox/delete/" + this.CurrentItem
                    .id, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-token": localStorage.getItem("token_admin"),
                        },
                    });
                console.log(response);
                if (this.selected == 'ที่ตั้งอุปกรณ์หมด') {
                    this.getDeviceAll();
                }
                if (this.selected == 'ที่ตั้งอุปกรณ์ตามจังหวัด') {
                    this.getDeviceWithProvince(this.CurrentItem.Province);
                }
                if (this.selected == 'ที่ตั้งอุปกรณ์ตามเลขไปรษณีย์') {
                    this.getDeviceWithPostocde(this.CurrentItem.ZipCode);
                }


            },
            async getDeviceAll() {
                const response = await fetch(process.env.VUE_APP_API_URL + "/api/namebox/all", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    },
                });
                var data = await response.json();
                this.ListDeviceAllItem = await data;
                console.log(data);
            },
            
            async getDeviceWithProvince(selected_province) {
                const response = await fetch(process.env.VUE_APP_API_URL + "/api/device/province/" +
                    selected_province, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-token": localStorage.getItem("token_admin"),
                        },
                    });
                var data = await response.json();
                this.ListDeviceProvinceItem = await data;
                console.log(data);
            },
            async getDeviceWithPostocde(selected_postcode) {
                const response = await fetch(process.env.VUE_APP_API_URL + "/api/device/zipcode/" +
                    selected_postcode, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-token": localStorage.getItem("token_admin"),
                        },
                    });
                var data = await response.json();
                this.ListDevicePostcodeItem = await data;
                console.log(data);
            },
            async getAreaAll() {
                const response = await fetch(process.env.VUE_APP_API_URL + "/api/zones/all", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    },
                });
                var data = await response.json();
                this.ListAreaItem = await data;
                console.log(data);
            },
            showPopupAfterDelay() {
                this.showPopup = true;
                setTimeout(() => {
                    this.showPopup = false;
                }, 1000);
            },
            async reloginWhenTimeOut() {

                const response = await fetch(process.env.VUE_APP_API_URL + "/api/auth/signin", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        username: this.insideLogin.username,
                        password: this.insideLogin.password
                    }),
                });
                var data = await response.json();
                if (data.accessToken == null) {
                    this.ErrorMessage = data.message;
                } else {
                    this.insideLogin.ErrorMessage = "";
                    if (data.roles == "ROLE_admin") {
                        localStorage.setItem("token_admin", data.accessToken);
                        localStorage.removeItem("token_user");
                        localStorage.setItem("verify_time", Date.now());
                        this.timeOut();
                        location.reload();
                    } else if (data.roles == "ROLE_user") {
                        this.ErrorMessage = "Role Error";
                    }
                }
                console.log(data);
            },
            async gethardwareByAdmin() {
                const response = await fetch(process.env.VUE_APP_API_URL + "/api/detailhardware/all", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    }
                });
                var data = await response.json();
                console.log(data.status);
                this.listHardware = data;
            },
            readRole() {
                var arearole = localStorage.getItem("adminrole_state");
                if (arearole == "true") {
                    this.roleArea = "ทั้งหมด";
                } else {
                    this.roleArea = localStorage.getItem("adminrole");
                }
            }
        },
        created() {
            checktokenAPI.check();
            this.readRole();
            this.getAreaAll();
            this.getDeviceAll();
            this.navigateToDeviceRead();
        }
    };
</script>

<style>
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #dddddd;
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        height: 0;
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>