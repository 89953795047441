
class CheckTimeService {
    checkTimeOut() {
    var timestampOld = localStorage.getItem('verify_time');
    if(Date.now()-timestampOld>=3600000){
        return true;
    }
    else{
        return false;
    }
    
  }

}

export default new CheckTimeService();