import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import router from './router'
import LongdoMap from 'longdo-map-vue'

// import Vue from 'vue';
// import App from './App.vue';


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(fas)



// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import {library} from '@fortawesome/fontawesome-svg-core';
// import { fas } from '@fortawesome/free-solid-svg-icons';
import VueApexCharts from "vue3-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
};

createApp(App).component('font-awesome-icon', FontAwesomeIcon)
.use(VueApexCharts).use(router).use(LongdoMap, {
  load: {
    apiKey: 'aaefd2a9c63d40fe689debd9776478e7',
   
  },

}).directive('resize-iframe', {
  mounted(el) {
    function resizeIframe() {
      el.style.height = el.contentWindow.document.body.scrollHeight + 'px';
    }

    el.addEventListener('load', resizeIframe);
  },
}).mount('#app')

