// import axios from 'axios';
// 
// const API_URL = 'http://webcode.me/';

class testcallapi{
    test123(www){
        console.log(www)
        // return axios
        // .get(API_URL , {
        // //   username: user.username,
        // //   password: user.password
        // })
        // .then(response => {
        //   if (response) {
        //     console.log(response);
        //     // localStorage.setItem('user', JSON.stringify(response.data));
        //   }
  
        //   return response.data;
        // });
    }
}

export default new testcallapi();