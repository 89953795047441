<template>

  <div class="" style="width: 99%;">
    <div class="row mt-3 justify-content-center">
      <div class="col-4 ">
        <img src="../../assets/logo.png" style="width: 300px;" alt="">
      </div>
      <div class="col-xl-5 col-lg-4 col-md-4 col-sm-3 col-4">
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-1 text-center "  style="font-size:20px;min-width: 80px;">
        <router-link to="/zoneadmin/statusdevice">
          <font-awesome-icon class="text-danger" icon="fa-solid fa-bell" />
        </router-link>
        <div class="mt-1" style="font-size: 10px;color:black">การแจ้งเตือน</div>
      </div>
      <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-1 text-center " style="font-size:20px;min-width: 80px;">
        <font-awesome-icon class="text-dark mt-2" icon="fa-solid fa-right-from-bracket" @click="logout()" />
              <div class="mt-1" style="font-size: 10px;color:black">ออกจากระบบ</div>
      </div>
    </div>

    <div class="mt-3">
      <div class="bg-light py-2">
        <div class="row">
          <div class="col-1">
          </div>
          <div class="col-4 ">
            <div><span style="font-size: 20px;">ผู้ใช้งาน: </span> {{ username }}</div>
            <div><span style="font-size: 20px;">พื้นที่:</span> {{ roleArea }} </div>
          </div>
          
        </div>

      </div>

      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-7 col-md-8 col-sm-10 col-11">
          <div class="row justify-content-center ">
            <div class="mt-5 col-xl-3  col-lg-6 col-md-6 col-sm-6 col-6">
              <router-link to="/zoneadmin/map" style="text-decoration: none; color: inherit;">
              <div class="card pb-4" style="height: 240px;">
                <span class="fab fa-envira mt-3">
                  <font-awesome-icon icon="fa-solid fa-map-location-dot" /></span>
                <p class="h6 pt-4">อุปกรณ์บบนแผนที่</p>
                <p class="p1 text-muted">
                  ดูรายละเอียดอุปกรณ์
                </p>
              </div>
            </router-link>
            </div>
            <div class="mt-5 col-xl-3  col-lg-6 col-md-6 col-sm-6 col-6">
              <router-link to="/zoneadmin/detaildevice" style="text-decoration: none; color: inherit;">
              <div class="card pb-4" style="height: 240px;">
                <span class="fab fa-envira mt-3">
                  <font-awesome-icon icon="fa-solid fa-bookmark" /></span>
                <p class="h6 pt-4">อุปกรณ์</p>
                <p class="p1 text-muted">
                  จัดการอุปกรณ์
                </p>
                <!-- <router-link to="/zoneadmin/detaildevice"><span class="fas fa-arrow-right">
                    <font-awesome-icon icon="fa-solid fa-arrow-right" /></span></router-link> -->
              </div>
            </router-link>
            </div>
            <div class="mt-5 col-xl-3  col-lg-6 col-md-6 col-sm-6 col-6">
              <router-link to="/zoneadmin/adduser" style="text-decoration: none; color: inherit;">
              <div class="card pb-4" style="height: 240px;">
                <span class="fab fa-envira mt-3">
                  <font-awesome-icon icon="fa-solid fa-user-plus" /></span>
                <p class="h6 pt-4">เพิ่มผู้ใช้งาน</p>
                <p class="p1 text-muted">
                  จัดการและเพิ่มผู้ใช้งาน
                </p>
                <!-- <router-link to="/zoneadmin/adduser"><span class="fas fa-arrow-right">
                    <font-awesome-icon icon="fa-solid fa-arrow-right" /></span></router-link> -->
              </div>
            </router-link>
            </div>

          </div>

        </div>
        <div class="col-xl-3 col-lg-3 col-md-10 col-sm-10 col-11 mt-5">
          <div class="content">
            <span class="h-6">อุปกรณ์</span>
            <p class=" h2 mb-4">SMSB Platform</p>
            <p class="text-muted mb-4"> &nbsp;&nbsp;&nbsp;ทางบริษัท มีเป้าหมายที่จะพัฒนาผลิตภัณฑ์อย่างต่อเนื่อง
              เพื่อให้มีสินค้าที่หลากหลาย โดยคำนึงถึงคุณภาพเป็นหลักสำคัญ ทั้งนี้เพื่อให้บรรลุตามวัตถุประสงค์
              ในด้านการผลิตสินค้าที่ได้มาตรฐาน ป้อนเข้าสู่ตลาดในระดับสากล และส่งผลเป็นส่วนหนึ่งในแรงขับดัน
              ต่อเทคโนโลยีด้านการสื่อสารภายในประเทศ ให้มีความพัฒนาก้าวหน้าในอนาคต.</p>
            <p><a href="https://www.sjp.co.th/" class="btn btn-warning">ไปยังเว็บไซต์ของเรา</a></p>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 170px;"></div>
  </div>
  <div class="pt-3" style="height:40vh;background-color: #5f5f5fdb;">
    <div class="row text-white justify-content-center">
      <div class="col-xl-2 col-lg-2 col-md-10 col-sm-10 col-11 mt-2 ">
        <div class="text-warning" style="font-weight: bold;">
          SJP Technology
        </div>
        <div style="font-size: 12px;">
          &nbsp;&nbsp;&nbsp;เรามีความมุ่งมั่นวิจัยและพัฒนาผลิตภัณฑ์
          สินค้าที่มีคุณภาพ ในราคาที่เหมาะสม
          และบริการจัดส่งสินค้า ให้ถึงมือลูกค้า
        </div>
      </div>
      <div class="col-xl-2 col-lg-2 col-md-10 col-sm-10 col-11 mt-2 ms-2">
        <div class="text-warning" style="font-weight: bold;">
          SJP Information System
        </div>
        <div style="font-size: 12px;">
          &nbsp;&nbsp;&nbsp;เราให้บริการทางดิจิตอล และนำเสนอโซลูชั่นให้กับลูกค้าที่ต้องการ
          นำเทคโนโลยีเพื่อใช้ในการแก้ไขปัญหาภายในองค์กร
        </div>
      </div>
      <div class="col-xl-2 col-lg-2 col-md-10 col-sm-10 col-11 mt-2 ms-2">
        <div class="text-warning" style="font-weight: bold;">
          เวลาเปิดทำการ
        </div>
        <div style="font-size: 12px;">
          เปิดทำการตั้งแต่เวลา 08:30 – 17:30 น
          <br>
          ปิดทำการวัน อาทิตย์
          <br>
          และวันหยุดนักขัตฤกษ์
        </div>
      </div>
      <div class="col-xl-2 col-lg-2 col-md-10 col-sm-10 col-11 mt-2 ms-2">
        <div class="text-warning" style="font-weight: bold;">
          SJP Technology Co.,Ltd
        </div>
        <div style="font-size: 12px;">
          28/15 ตำบล พันท้ายนรสิงห์ อำเภอเมืองสมุทรสาคร สมุทรสาคร 74000
          <br>
          โทร.

          02 408 4343
          ,
          03 444 6999
        </div>
      </div>
    </div>
    <hr class="text-white">
    <div class="text-center h1 text-white mt-4">
      SJP <span style="font-size: 20px;">Technology</span>
    </div>
  </div>
</template>

<script>

import checktokenAPI from "../connectBackend/checkTokenFirst";
  import {
    useCurrentTime
  } from "../../composables/useCurrentTime";
  export default {
    name: "CurrentTimeExample",
    setup() {
      const {
        currentTime
      } = useCurrentTime();
      console.log(currentTime.value);
      return {
        currentTime
      };
    },
    data() {
      return {
        roleArea:"",
        username:"",
      };
    },
    methods:{
      logout(){
        localStorage.removeItem("token_admin");
        localStorage.removeItem("adminrole");
        localStorage.removeItem("adminrole_state");
        localStorage.removeItem("infolocations");
        localStorage.removeItem("username");
        localStorage.removeItem("verify_time");
        localStorage.removeItem("partcctv");
        this.$router.replace({path: "/login"});
      },
      readRole(){
        var arearole = localStorage.getItem("adminrole_state");
        this.username = localStorage.getItem("username");
        if(arearole=="true"){
          this.roleArea="ทั้งหมด";
        }else{
          this.roleArea = localStorage.getItem("adminrole");
        }
      }
    },
    created(){
      checktokenAPI.check("123456789");
      this.readRole();
    }
  };
</script>

<style>
  .round-button {
    display: block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    border: 2px solid #f5f5f5;
    border-radius: 50%;
    color: #f5f5f5;
    text-align: center;
    text-decoration: none;
    background: #555777;
    box-shadow: 0 0 3px gray;
    font-size: 20px;
    font-weight: bold;
  }

  .round-button:hover {
    background: #777555;
  }


  p {
    margin: 0px;
  }

  .container {
    margin: 50px auto;
  }

  .card {
    /* width: 220px; */

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  }

  .card .fas.fa-arrow-right {
    position: absolute;
    bottom: -100px;
    background-color: #ffd100;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #ffffff;
    opacity: 0;
    transform: translateY(50%) translateX(-50%);
    transition: all 0.5s ease;
  }

  .card:hover .fas.fa-arrow-right {
    bottom: 0px;
    opacity: 1;
  }

  .card .fab.fa-envira,
  .fas.fa-cut,
  .fas.fa-people-carry {
    font-size: 40px;
    background-color: #0000000d;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #ffd100;
  }

  .card .h4 {
    font-weight: 700;
  }

  .container .content {
    max-width: 300px;
  }

  .container .content .h-6 {
    color: #ffd100;
    font-weight: 600;
  }

  .container .content .btn.btn-primary {
    padding: 15px;
    background-color: #ffd100;
    color: white;
    border-radius: 0px;
    border: none;
    box-shadow: none;
  }

  .card:hover .fab.fa-envira,
  .card:hover .fas.fa-cut,
  .card:hover .fas.fa-people-carry {
    color: #ffffff;
    background-color: #ffd100;
  }

  @media (max-width:990px) {
    .container .content {
      max-width: 100%;
    }
  }

  /* @media (max-width:500px) {
      body {
        padding: 25px;
      }
  
      .card {
        width: 100%;
      }
  
      .content {
        padding: 25px;
      }
    } */
</style>