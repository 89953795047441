<template>

  <router-view />
  <!-- <dashboard/> -->
  <!-- <indashboard/> -->
</template>

<script>
export default {

  name: 'App',
  components: {
  },
   
}

</script>


   
<style>
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap');
html, body,a,div,table,tr,td {
  font-family: 'Kanit', sans-serif;
}

#app {
  font-family: 'Kanit', sans-serif;
}
</style>