<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <div class="container-fluid">
      <router-link to="/supperadmin/landing">
        <img src="../../assets/logo.png" style="width: 250px;" alt="">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <router-link to="/supperadmin/map" style="text-decoration: none;">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">อุปกรณ์บนแผนที่</a>
            </li>
          </router-link>
          <!-- <router-link to="/supperadmin/area" style="text-decoration: none;">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#">เขตพื้นที่</a>
            </li>
          </router-link>
          <router-link to="/supperadmin/adduser" style="text-decoration: none;">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#">ผู้ใช้งาน</a>
            </li>
          </router-link> -->

          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              อุปกรณ์
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link to="/supperadmin/detaildevice" style="text-decoration: none;"><a class="dropdown-item"
                    href="">รายละเอียดที่ตั้งอุปกรณ์</a></router-link>
              </li>
              <li>
                <router-link to="/supperadmin/detailhardware" style="text-decoration: none;"><a class="dropdown-item"
                    href="#">รายละเอียดรุ่นอุปกรณ์</a></router-link>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" href="#">รายงานอุปกรณ์ออฟไลน์</a></li>
            </ul>
          </li> -->
        </ul>

      </div>
    </div>
  </nav>

  <body class="">

    <div style="height: 50px;"></div>
    <br>
    <!-- {{ load }} -->
    <!-- {{ cctvListall }} -->


    <!-- <div :class="{ invisible: !value1 }">I'm visible!</div>
  <div :class="{ invisible: !value2 }">Only my space is visible!</div>
  <div>Dummy text</div> -->

    <div class="row">
      <div class="col-12">


        <!-- <div>{{process.env.VUE_APP_RTSP_URL}}</div> -->

        <div class="row  mt-2">
          <div v-if="hardwareDeviceCameraState.status==true" class="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12 "
            style="height: 370px;min-width: 600px;">
            <div class=""> <span>เลือกกล้อง </span>
              <select class="" v-model="selectedCCTV" style="height: 32px;width: 38px;">
                <option disabled value="ที่ตั้งอุปกรณ์หมด">{{ selectedCCTV }}</option>
                <option v-for="(item, index) in cctvListalluse" :key="index">{{ index+1 }}</option>
              </select></div>
            <iframe :src='`${rtspUrl}/live/${cctvListalluse[selectedCCTV-1].part}`' style="width: 100%; height: 100%;"
              frameborder="0" allowfullscreen></iframe>
          </div>
          <div v-if="hardwareDeviceCameraState.status==false" class="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12 "
            style="height: 370px;min-width: 600px; ">
            <div class="text-center"
              style="width: 570px; height: 330px; background-color: rgb(237, 237, 237); display: flex; flex-direction: column; justify-content: center; align-items: center;">
              <div>No Camera</div>
            </div>
          </div>

          <div class="ms-2 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 text-start">
            <div style="height: 30px;"></div>
            <h4>ข้อมูลกล่อง</h4>
            <h5 class="mt-2">ข้อมูลที่ตั้ง</h5>
            <div style="font-size: 16px;">
              <div style="">ตำแหน่งที่ตั้ง : {{ infolocation.name }}</div>
              <div style="">จังหวัด : {{ infolocation.Province }}</div>
              <div style="">ไปรษณีย์ : {{ infolocation.Zipcode }}</div>
              <div style="">ละติจูด, ลองจิจูด: {{infolocation.lat}},{{infolocation.lon}} </div>
            </div>
            <div v-if="hardwareDeviceSensorState.status==true">
              <h5>ข้อมูล Weather Station</h5>
              <div style="font-size: 16px;">
                <div v-if="mqtt.status==true">device : <span style="color: green;">● <span
                      style="font-size: 14px;">Online</span></span></div>
                <div v-else>device : <span style="color: red;">● <span style="font-size: 14px;">Offline</span></span>
                </div>
                <div>Weather Station ID : <span>{{ hardwareDeviceSensorState.ID_run }}</span></div>
              </div>
            </div>
            <div v-if="hardwareDeviceCameraState.status==true">
              <h5>ข้อมูลกล้อง</h5>
              <div v-if="infolocation.status=='true'">CCTV : <span style="color: green;">● <span
                    style="font-size: 14px;">Online</span></span></div>
              <div v-else>CCTV : <span style="color: red;">● <span style="font-size: 14px;">Offline</span></span></div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hardwareDeviceSensorState.status==true" class="">

      <div class="row   ms-4" v-if="selectedModeChart==0">
        <h5 type="button" @click="selectedModeChart=0,oneTimeState=false"
          class="col-xl-1 col-lg-1 col-md-3 col-sm-3 col-5 ms-2 text-warning" style="min-width: 200px;">
          สภาพอากาศปัจจุบัน</h5>
        <h5 type="button" @click="selectedModeChart=1" class="col-xl-1 col-lg-1 col-md-3 col-sm-3 col-5 ms-2"
          style="color: gray;min-width: 200px;">กราฟสภาพอากาศ</h5>
        <h5 v-if='load["sensor"]["Rain"]!=null' type="button" @click="selectedModeChart=2,oneTimeState=false"
          class="col-xl-2 col-lg-2 col-md-4 col-sm- col-5 ms-2" style="min-width: 200px;color: gray;">
          การแจ้งเตือนสภาพอากาศ</h5>
      </div>
      <div class="row  ms-4" v-else-if="selectedModeChart==1">
        <h5 type="button" @click="selectedModeChart=0,oneTimeState=false"
          class="col-xl-1 col-lg-1 col-md-3 col-sm-3 col-5 ms-2" style="color: gray;min-width: 200px;">
          สภาพอากาศปัจจุบัน
        </h5>
        <h5 type="button" class="col-xl-1 col-lg-1 col-md-3 col-sm-3 col-5 ms-2 text-warning" style="min-width: 200px;"
          @click="selectedModeChart=1;">กราฟสภาพอากาศ</h5>
        <h5 v-if='load["sensor"]["Rain"]!=null' type="button" @click="selectedModeChart=2,oneTimeState=false"
          class="col-xl-2 col-lg-2 col-md-4 col-sm- col-5 ms-2" style="min-width: 200px;color: gray;">
          การแจ้งเตือนสภาพอากาศ</h5>
      </div>
      <div class="row  ms-4" v-else-if="selectedModeChart==2">
        <h5 type="button" @click="selectedModeChart=0,oneTimeState=false"
          class="col-xl-1 col-lg-1 col-md-3 col-sm-3 col-5 ms-2" style="color: gray;min-width: 200px;">
          สภาพอากาศปัจจุบัน
        </h5>
        <h5 type="button" class="col-xl-1 col-lg-1 col-md-3 col-sm-3 col-5 ms-2 " style="min-width: 200px;color: gray;"
          @click="selectedModeChart=1;">กราฟสภาพอากาศ</h5>
        <h5 v-if='load["sensor"]["Rain"]!=null' type="button" @click="selectedModeChart=2,oneTimeState=false"
          class="col-xl-2 col-lg-2 col-md-4 col-sm- col-5 ms-2 text-warning" style="min-width: 200px;">
          การแจ้งเตือนสภาพอากาศ</h5>
      </div>

      <div v-if="selectedModeChart==0" style="background-color: #dfdfdf;">
        <div class="row justify-content-center mt-3" v-if="mqtt.status==true">
          <div v-if='load["sensor"]["Ultrasonic"]!=null' class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-4">
            <div class="text-center">ระดับน้ำ
              <button type="button" data-bs-toggle="modal" data-bs-target="#ultrasonicModal" class="btn btn-secondary"
                @click="getHightData()">สอบเทียบ</button>
            </div>
            <div class="text-center">
            </div>
            <div class="col-12 ">
              <div class="row justify-content-center">
                <div class="col-5" id="chart">
                  <apexchart type="bar" height="350" width="160" :options="chartOptionsUltrasonic"
                    :series="seriesUltrasonic" ref="updateRealtimeUltra"></apexchart>
                </div>
              </div>
            </div>
          </div>

          <div v-if='load["sensor"]["pm25"]!=null' class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-4">
            <div class="col text-center bg-light py-2">
              <div class="text-start ms-3">ประเมินสคุณภาพอากาศ PM2.5</div>
              <div class="row  mt-2 ">
                <div class="col-4 text-end">
                  <img v-if="load['sensor']['pm25']<=25"
                    src="https://firebasestorage.googleapis.com/v0/b/skfarm-demo.appspot.com/o/emoji%2Fccdc-01-en.png?alt=media&token=7d4f3f2c-07d7-479b-b319-d86fd0c1bec4"
                    alt="" height="80">

                  <img v-if="load['sensor']['pm25']>=26 && load['sensor']['pm25']<=37"
                    src="https://firebasestorage.googleapis.com/v0/b/skfarm-demo.appspot.com/o/emoji%2Fccdc-02-en.png?alt=media&token=783f5308-a6a3-4c3f-9902-f4c164c74c56"
                    alt="" height="80">

                  <img v-if="load['sensor']['pm25']>=38 && load['sensor']['pm25']<=50"
                    src="https://firebasestorage.googleapis.com/v0/b/skfarm-demo.appspot.com/o/emoji%2Fccdc-03-en.png?alt=media&token=860a9e7c-6316-4ecd-a114-cc7c9d16b0d7"
                    alt="" height="80">

                  <img v-if="load['sensor']['pm25']>=51 && load['sensor']['pm25']<=90"
                    src="https://firebasestorage.googleapis.com/v0/b/skfarm-demo.appspot.com/o/emoji%2Fccdc-04-en.png?alt=media&token=d0d9da5f-eae3-43c1-8523-ff3b3b04f6ab"
                    alt="" height="80">

                  <img v-if="load['sensor']['pm25']>=91"
                    src="https://firebasestorage.googleapis.com/v0/b/skfarm-demo.appspot.com/o/emoji%2Fccdc-05-en.png?alt=media&token=6bcd7469-5279-4532-bd08-78bdc32d9275"
                    alt="" height="80">


                </div>
                <div class="col-7 fs-6">
                  <div class="fs-4">{{load['sensor']['pm25']}} µg/m3 </div>
                  <div v-if="load['sensor']['pm25']<=25" class="fs-6" style="color: #0288D1;">คุณภาพอากาศดีมาก</div>
                  <div v-if="load['sensor']['pm25']>=26 && load['sensor']['pm25']<=37" class="fs-6"
                    style="color: rgb(136, 187, 81);">คุณภาพดี</div>
                  <div v-if="load['sensor']['pm25']>=38 && load['sensor']['pm25']<=50" class="fs-6"
                    style="color: rgb(227 208 69);">คุณภาพอากาศปานกลาง</div>
                  <div v-if="load['sensor']['pm25']>=51 && load['sensor']['pm25']<=90" class="fs-6"
                    style="color: rgb(253, 195, 95);">คุณภาพอากาศมีผลกระทบต่อสุขภาพ</div>
                  <div v-if="load['sensor']['pm25']>=91" class="fs-6" style="color: rgb(237, 93, 93);">
                    คุณภาพอากาศมีผลกระทบต่อสุขภาพมาก</div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-2 text-center " style=" font-size:10px;background-color: #3f91be;">
                  0-25
                </div>
                <div class="col-2 text-center " style=" font-size:10px;background-color: rgb(169, 199, 137);">
                  26-37
                </div>
                <div class="col-2 text-center " style=" font-size:10px;background-color: rgb(243, 243, 131);">
                  38-50
                </div>
                <div class="col-2 text-center " style=" font-size:10px;background-color: rgb(255, 211, 134);">
                  51-90
                </div>
                <div class="col-2 text-center " style=" font-size:10px;background-color: rgb(255, 121, 121);">
                  91 up
                </div>
              </div>
            </div>

            <div class="col text-center bg-light py-2 mt-3 ">
              <div class="text-start ms-3">ประเมินสคุณภาพอากาศ PM10</div>
              <div class="row  mt-2 ">

                <div class="row  mt-2 ">
                  <div class="col-4 text-end">
                    <img v-if="load['sensor']['pm10']<=50"
                      src="https://firebasestorage.googleapis.com/v0/b/skfarm-demo.appspot.com/o/emoji%2Fccdc-01-en.png?alt=media&token=7d4f3f2c-07d7-479b-b319-d86fd0c1bec4"
                      alt="" height="80">

                    <img v-if="load['sensor']['pm10']>=51 && load['sensor']['pm10']<=80"
                      src="https://firebasestorage.googleapis.com/v0/b/skfarm-demo.appspot.com/o/emoji%2Fccdc-02-en.png?alt=media&token=783f5308-a6a3-4c3f-9902-f4c164c74c56"
                      alt="" height="80">

                    <img v-if="load['sensor']['pm10']>=81 && load['sensor']['pm10']<=120"
                      src="https://firebasestorage.googleapis.com/v0/b/skfarm-demo.appspot.com/o/emoji%2Fccdc-03-en.png?alt=media&token=860a9e7c-6316-4ecd-a114-cc7c9d16b0d7"
                      alt="" height="80">

                    <img v-if="load['sensor']['pm10']>=121 && load['sensor']['pm10']<=180"
                      src="https://firebasestorage.googleapis.com/v0/b/skfarm-demo.appspot.com/o/emoji%2Fccdc-04-en.png?alt=media&token=d0d9da5f-eae3-43c1-8523-ff3b3b04f6ab"
                      alt="" height="80">

                    <img v-if="load['sensor']['pm10']>=181"
                      src="https://firebasestorage.googleapis.com/v0/b/skfarm-demo.appspot.com/o/emoji%2Fccdc-05-en.png?alt=media&token=6bcd7469-5279-4532-bd08-78bdc32d9275"
                      alt="" height="80">


                  </div>
                  <div class="col-7 fs-6">
                    <div class="fs-4">{{load['sensor']['pm10']}} µg/m3 </div>
                    <div v-if="load['sensor']['pm10']<=50" class="fs-6" style="color: #0288D1;">คุณภาพอากาศดีมาก</div>
                    <div v-if="load['sensor']['pm10']>=51 && load['sensor']['pm10']<=80" class="fs-6"
                      style="color: rgb(136, 187, 81);">คุณภาพดี</div>
                    <div v-if="load['sensor']['pm10']>=81 && load['sensor']['pm10']<=120" class="fs-6"
                      style="color: rgb(227 208 69);">คุณภาพอากาศปานกลาง</div>
                    <div v-if="load['sensor']['pm10']>=121 && load['sensor']['pm10']<=180" class="fs-6"
                      style="color: rgb(253, 195, 95);">คุณภาพอากาศมีผลกระทบต่อสุขภาพ</div>
                    <div v-if="load['sensor']['pm10']>=181" class="fs-6" style="color: rgb(237, 93, 93);">
                      คุณภาพอากาศมีผลกระทบต่อสุขภาพมาก</div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-2 text-center " style=" font-size:10px;background-color: #3f91be;">
                    0-50
                  </div>
                  <div class="col-2 text-center " style=" font-size:10px;background-color: rgb(169, 199, 137);">
                    51-80
                  </div>
                  <div class="col-2 text-center " style=" font-size:10px;background-color: rgb(243, 243, 131);">
                    81-120
                  </div>
                  <div class="col-2 text-center " style=" font-size:10px;background-color: rgb(255, 211, 134);">
                    121-180
                  </div>
                  <div class="col-2 text-center " style=" font-size:10px;background-color: rgb(255, 121, 121);">
                    181 up
                  </div>
                </div>
              </div>
            </div>

            <div class="col text-center bg-light py-2 mt-3 " v-if='load["sensor"]["rainday"]!=null'>
              <div class="text-start ms-3">ปริมาณน้ำฝนสะสม (Rain Gauge) </div>
              <div v-if="load['sensor']['rainday']>=90.1" class="fs-5" style="color: rgb(253, 195, 95);">
                คำเตือน: ปริมาณน้ำฝนสะสมในวันนี้มีฝนตกหนักมาก </div>
              <div class="row  mt-2 ">

                <div class="row  mt-2 ">
                  <div class="col-4 text-end">
                    <img
                      src="https://static.vecteezy.com/system/resources/thumbnails/009/585/430/small/rain-drop-from-cartoony-cloud-free-free-png.png"
                      alt="" height="80">

                  </div>
                  <div class="col-7 fs-6">
                    <div class="fs-4">{{ formatFloat(load['sensor']['rainday'],2)  }} mm</div>
                    <div>(ปริมาณใน 24 ชั่วโมง)</div>
                    <div v-if="load['sensor']['rainday']<=10" class="fs-6" style="color: #0288D1;">ฝนเล็กน้อย (Light
                      rain)</div>
                    <div v-if="load['sensor']['rainday']>=11 && load['sensor']['rainday']<=35" class="fs-6"
                      style="color: rgb(136, 187, 81);"> ฝนปานกลาง (Moderate rain)</div>
                    <div v-if="load['sensor']['rainday']>=35.1 && load['sensor']['rainday']<=90" class="fs-6"
                      style="color: rgb(227 208 69);">ฝนหนัก (Heavy rain)</div>
                    <div v-if="load['sensor']['rainday']>=90.1" class="fs-6" style="color: rgb(253, 195, 95);">
                      ฝนหนักมาก (Very heavy rain) </div>
                  </div>
                  <div class="row justify-content-center my-2">
                    <div class="col-2 text-center " style=" font-size:10px;background-color: #3f91be;">
                      0-10
                    </div>
                    <div class="col-2 text-center " style=" font-size:10px;background-color: rgb(169, 199, 137);">
                      11-35
                    </div>
                    <div class="col-2 text-center " style=" font-size:10px;background-color: rgb(243, 243, 131);">
                      36-90
                    </div>
                    <div class="col-2 text-center " style=" font-size:10px;background-color: rgb(255, 211, 134);">
                      90
                    </div>

                  </div>
                </div>
                <div class="row " style="background-color: #F7F7F7;">
                  <div class="col-8">
                    <div class="text-start" style="color: #454545;">ฝนสะสม 1 วัน</div>
                    <div class="text-start" style="color: #454545;">ฝนสะสม 1 สัปดาห์</div>
                    <div class="text-start" style="color: #454545;">ฝนสะสม 1 เดือน</div>
                    <div class="text-start" style="color: #454545;">ฝนสะสม 3 เดือน</div>
                    <div class="text-start" style="color: #454545;">ฝนสะสม 6 เดือน</div>
                    <div class="text-start" style="color: #454545;">ฝนสะสม 1 ปี</div>
                  </div>
                  <div class="col-4">
                    <div class="text-end">{{ rainAll['rain1d'] }} mm.</div>
                    <div class="text-end">{{ rainAll['rain7d'] }} mm.</div>
                    <div class="text-end">{{ rainAll['rain30d'] }} mm.</div>
                    <div class="text-end">{{ rainAll['rain3m'] }} mm.</div>
                    <div class="text-end">{{ rainAll['rain6m'] }} mm.</div>
                    <div class="text-end">{{ rainAll['rain1y'] }} mm.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mt-3">
            <div
              class="row justify-content-xl-start justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-center ">
              <h4 class="">Realtime</h4>

              <div v-if='load["sensor"]["Ultrasonic"]!=null'
                class="col-xl-2 col-xl-2 col-md-5 col-sm-5 col-5 mt-2 bg-light   mx-2"
                style="height: 120px;background-color: #183153;">
                <div style=""> Ultrasonic (ระดับน้ำ)</div>
                <div class="text-center mt-3" style="font-size: 30px;color: #4682B4;">
                  <!-- {{ load["sensor"]["Ultrasonic"] }} -->
                  {{ Math.abs((heightAll-((load["sensor"]["Ultrasonic"])/100)).toFixed(2)) }}</div>
                <div>m</div>
              </div>


              <div v-if='load["sensor"]["temp"]!=null'
                class="col-xl-2 col-xl-2 col-md-5 col-sm-5 col-5 mt-2 bg-light   mx-2"
                style="height: 120px;background-color: #183153;">
                <div style="">
                  <font-awesome-icon :icon="['fas', 'temperature-empty']" /> Temperature</div>
                <div class="text-center mt-3" style="font-size: 30px;color: #4682B4;">
                  {{ formatFloat(load["sensor"]["temp"],2)  }}</div>
                <div class="">°C</div>
              </div>

              <div v-if='load["sensor"]["hum"]!=null'
                class="col-xl-2 col-xl-2 col-md-5 col-sm-5 col-5 mt-2  bg-light   mx-2"
                style="height: 120px;background-color: #DAF7A6;">
                <div>
                  <font-awesome-icon :icon="['fas', 'droplet']" /> Humidity</div>
                <div class="text-center mt-3" style="font-size: 30px;color: #4682B4;">
                  {{ formatFloat(load["sensor"]["hum"],2)  }} </div>
                <div>%RH</div>
              </div>

              <div v-if='load["sensor"]["Lux"]!=null'
                class="col-xl-2 col-xl-2 col-md-5 col-sm-5 col-5 mt-2  bg-light   mx-2"
                style="height: 120px;background-color: #FFC300;">
                <div>
                  <font-awesome-icon :icon="['fas', 'sun']" /> Light Intensity</div>
                <div class="text-center mt-3" style="font-size: 30px;color:#32CD32;">
                  {{ formatFloat(load["sensor"]["Lux"],2)  }}</div>
                <div>Lux</div>
              </div>

              <div v-if='load["sensor"]["Pressure"]!=null'
                class="col-xl-2 col-xl-2 col-md-5 col-sm-5 col-5 mt-2  bg-light   mx-2"
                style="height: 120px;background-color: #F19C90;">
                <div>
                  <font-awesome-icon :icon="['fas', 'gauge']" /> Pressure</div>
                <div class="text-center mt-3" style="font-size: 30px;color:#32CD32;">
                  {{ formatFloat(load["sensor"]["Pressure"],2)  }} </div>
                <div>Kpa</div>
              </div>

              <div v-if='load["sensor"]["Noise"]!=null'
                class="col-xl-2 col-xl-2 col-md-5 col-sm-5 col-5 mt-2  bg-light   mx-2"
                style="height: 120px;background-color: #F19C90;">
                <div>
                  <font-awesome-icon :icon="['fas', 'volume-high']" /> Noise</div>
                <div class="text-center mt-3" style="font-size: 30px;color:#32CD32;">
                  {{ formatFloat(load["sensor"]["Noise"],2)  }} </div>
                <div>dB</div>
              </div>

              <div v-if='load["sensor"]["Rain"]!=null'
                class="col-xl-2 col-xl-2 col-md-5 col-sm-5 col-5 mt-2  bg-light   mx-2"
                style="height: 120px;background-color: #D7B1FF;">
                <div>
                  <font-awesome-icon :icon="['fas', 'cloud-sun-rain']" />
                  <font-awesome-icon :icon="['fas', 'cloud-rain']" /> Rain Gauge</div>
                <div class="text-center mt-3" style="font-size: 30px;color:#DC143C;">
                  {{ formatFloat(load['sensor']['rainday'],2)  }} </div>
                <div>mm</div>
              </div>

            </div>
            <hr class="">

            <div
              class="row justify-content-xl-start justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-center ">
              <div v-if='load["sensor"]["pm25"]!=null' class=" col-xl-3 col-lg-3 col-md-5 col-sm-5 col-5 ">
                <div class="bg-white shadow-sm  ">
                  <div class="ms-2">
                    <font-awesome-icon :icon="['fas', 'mask-face']" /> PM 2.5</div>
                  <!-- <div class="fs-4">{{load['sensor']['pm25']}} µg/m3 </div> -->
                  <div v-if="load['sensor']['pm25']<=25" class="fs-3 text-center" style="color: #0288D1;">
                    {{load["sensor"]["pm25"]}} </div>
                  <div v-if="load['sensor']['pm25']>=26 && load['sensor']['pm25']<=37" class="fs-3 text-center"
                    style="color: rgb(136, 187, 81);">{{load["sensor"]["pm25"]}} </div>
                  <div v-if="load['sensor']['pm25']>=38 && load['sensor']['pm25']<=50" class="fs-3 text-center"
                    style="color: rgb(227 208 69);">{{load["sensor"]["pm25"]}} </div>
                  <div v-if="load['sensor']['pm25']>=51 && load['sensor']['pm25']<=90" class="fs-3 text-center"
                    style="color: rgb(253, 195, 95);">{{load["sensor"]["pm25"]}} </div>
                  <div v-if="load['sensor']['pm25']>=91" class="fs-3 text-center" style="color: rgb(237, 93, 93);">
                    {{load["sensor"]["pm25"]}}</div>

                  <!-- <div class="text-center" style="font-size: 30px;"> {{load["sensor"]["pm25"]}} </div> -->
                  <hr>
                  <div class="text-center">PM2.5 (ug/m3)</div>
                </div>
              </div>

              <div v-if='load["sensor"]["pm10"]!=null' class=" col-xl-3 col-lg-3 col-md-5 col-sm-5 col-5 ">
                <div class="bg-white shadow-sm  ">
                  <div class="ms-2">
                    <font-awesome-icon :icon="['fas', 'mask-face']" /> PM 10</div>

                  <div v-if="load['sensor']['pm10']<=50" class="text-center fs-3" style="color: #0288D1;">
                    {{ load["sensor"]["pm10"] }}</div>
                  <div v-if="load['sensor']['pm10']>=51 && load['sensor']['pm10']<=80" class="text-center fs-3"
                    style="color: rgb(136, 187, 81);">{{ load["sensor"]["pm10"] }}</div>
                  <div v-if="load['sensor']['pm10']>=81 && load['sensor']['pm10']<=120" class="text-center fs-3"
                    style="color: rgb(227 208 69);">{{ load["sensor"]["pm10"] }}</div>
                  <div v-if="load['sensor']['pm10']>=121 && load['sensor']['pm10']<=180" class="text-center fs-3"
                    style="color: rgb(253, 195, 95);">{{ load["sensor"]["pm10"] }}</div>
                  <div v-if="load['sensor']['pm10']>=181" class="text-center fs-3" style="color: rgb(237, 93, 93);">
                    {{ load["sensor"]["pm10"] }}</div>

                  <!-- <div class="text-center" style="font-size: 30px;"> {{load["sensor"]["pm10"]}} </div> -->
                  <hr>
                  <div class="text-center">PM10 (ug/m3)</div>
                </div>
              </div>

              <div v-if='load["sensor"]["Co2"]!=null' class="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-5">
                <div class="bg-white shadow-sm ">
                  <div class="ms-2">
                    <font-awesome-icon :icon="['fas', 'smog']" /> Co2</div>
                  <div class="text-center" style="font-size: 30px;color: blue;"> {{load["sensor"]["Co2"]}} </div>
                  <hr>
                  <div class="text-center">ppm</div>
                </div>
              </div>

              <div v-if='load["sensor"]["Co2_2"]!=null' class="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-5">
                <div class="bg-white shadow-sm ">
                  <div class="ms-2">
                    <font-awesome-icon :icon="['fas', 'smog']" /> Co2</div>
                  <div class="text-center" style="font-size: 30px;"> {{load["sensor"]["Co2_2"]}} </div>
                  <hr>
                  <div class="text-center">ppm</div>
                </div>
              </div>

              <div style="height: 230px;"></div>
            </div>
          </div>
        </div>
        <div v-else class="" style="height: 48vh; background-color: rgbrgb(240, 241, 243);">
          <br>
          <div class="text-center mt-3 h5 "> loading...</div>
          <!-- <div style="height: 230px;"></div> -->
        </div>
      </div>



      <div class="row justify-content-center" v-else-if="selectedModeChart==1">
        <div class="col-11 bg-white">
          <div class="row  justify-content-center mt-4">
            <h5 class="col-xl-1  col-lg-2 col-md-2 col-sm-3 col-4 mt-2 ms-2">
              เลือกช่วงเวลา:
            </h5>
            <div class="col-xl-4 col-lg-4 col-md-8 col-sm-8 col-12 ">
              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="cc4" id="cc4_1" autocomplete="off"
                  @click="CallchartAll('1hr','','')" checked>
                <label class="btn btn-outline-secondary" for="cc4_1">1 HR</label>

                <input type="radio" class="btn-check" name="cc4" id="cc4_2" autocomplete="off"
                  @click="CallchartAll('1d','','')">
                <label class="btn btn-outline-secondary" for="cc4_2">1 D</label>

                <input type="radio" class="btn-check" name="cc4" id="cc4_3" autocomplete="off"
                  @click="CallchartAll('1w','','')">
                <label class="btn btn-outline-secondary" for="cc4_3">1 W</label>

                <input type="radio" class="btn-check" name="cc4" id="cc4_4" autocomplete="off"
                  @click="CallchartAll('1m','','')">
                <label class="btn btn-outline-secondary" for="cc4_4">1 M</label>

                <input type="radio" class="btn-check" name="cc4" id="cc4_5" autocomplete="off"
                  @click="CallchartAll('3m','','')">
                <label class="btn btn-outline-secondary" for="cc4_5">3 M</label>

                <input type="radio" class="btn-check" name="cc4" id="cc4_6" autocomplete="off"
                  @click="CallchartAll('6m','','')">
                <label class="btn btn-outline-secondary" for="cc4_6">6 M</label>

                <input type="radio" class="btn-check" name="cc4" id="cc4_7" autocomplete="off">
                <label class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#selectChartModal"
                  for="cc4_7">Date</label>
              </div>
            </div>
          </div>
          <br>
          {{ checkChart() }}

          <div v-if='load["sensor"]["temp"]!=null' id="chart" class="bg-white ">
            <div class="ms-2"> Temperature
            </div>
            <div class="mt-2" id="chart">
              <apexchart type="area" height="350" :options="chartOptions_all_temp" :series="series_all_temp"
                ref="updatetempAll">
              </apexchart>
            </div>
          </div>

          <div v-if='load["sensor"]["Ultrasonic"]!=null' id="chart" class="bg-white ">
            <div class="ms-2"> Ultrasonic
            </div>
            <div class="mt-2" id="chart">
              <apexchart type="area" height="350" :options="chartOptions_all_ultrasonic" :series="series_all_ultrasonic"
                ref="updateultrasonicAll">
              </apexchart>
            </div>
          </div>

          <div v-if='load["sensor"]["hum"]!=null' id="chart" class="bg-white  mt-5">
            <div class="ms-2"> Humidity
            </div>
            <div class="mt-2" id="chart">
              <apexchart type="area" height="350" :options="chartOptions_all_hum" :series="series_all_hum"
                ref="updatehumAll">
              </apexchart>
            </div>
          </div>

          <div v-if='load["sensor"]["Lux"]!=null' id="chart" class="bg-white  mt-5">
            <div class="ms-2"> Light Lux
            </div>
            <div class="mt-2" id="chart">
              <apexchart type="area" height="350" :options="chartOptions_all_lux" :series="series_all_lux"
                ref="updateluxAll">
              </apexchart>
            </div>
          </div>

          <div v-if='load["sensor"]["pm10"]!=null' id="chart" class="bg-white  mt-5">
            <div class="ms-2"> PM10
            </div>
            <div class="mt-2" id="chart">
              <apexchart type="area" height="350" :options="chartOptions_all_pm10" :series="series_all_pm10"
                ref="updatepm10All">
              </apexchart>
            </div>
          </div>

          <div v-if='load["sensor"]["pm25"]!=null' id="chart" class="bg-white  mt-5">
            <div class="ms-2"> PM2.5
            </div>
            <div class="mt-2" id="chart">
              <apexchart type="area" height="350" :options="chartOptions_all_pm25" :series="series_all_pm25"
                ref="updatepm25All">
              </apexchart>
            </div>
          </div>


          <div v-if='load["sensor"]["Pressure"]!=null' id="chart" class="bg-white  mt-5">
            <div class="ms-2"> Pressure
            </div>
            <div class="mt-2" id="chart">
              <apexchart type="area" height="350" :options="chartOptions_all_pressure" :series="series_all_pressure"
                ref="updatepressureAll">
              </apexchart>
            </div>
          </div>

          <div v-if='load["sensor"]["Noise"]!=null' id="chart" class="bg-white  mt-5">
            <div class="ms-2"> Noise
            </div>
            <div class="mt-2" id="chart">
              <apexchart type="area" height="350" :options="chartOptions_all_noise" :series="series_all_noise"
                ref="updatenoiseAll">
              </apexchart>
            </div>
          </div>

          <div v-if='load["sensor"]["Rain"]!=null' id="chart" class="bg-white  mt-5">
            <div class="ms-2"> Rain
            </div>
            <div class="mt-2" id="chart">
              <apexchart type="area" height="350" :options="chartOptions_all_rain" :series="series_all_rain"
                ref="updaterainAll">
              </apexchart>
            </div>

            <div class="ms-2"> Rain Bar Chart
            </div>
            <div id="chart">
              <apexchart type="bar" height="350" :options="chartOptions_column_rain" :series="series_column_rain"
                ref="updateRainAllColumn"></apexchart>
            </div>
          </div>

          <div v-if='load["sensor"]["Co2"]!=null' id="chart" class="bg-white  mt-5">
            <div class="ms-2"> Co2
            </div>
            <div class="mt-2" id="chart">
              <apexchart type="area" height="350" :options="chartOptions_all_co2" :series="series_all_co2"
                ref="updateco2All">
              </apexchart>
            </div>
          </div>


        </div>

      </div>

      <div class="row justify-content-center" v-else-if="selectedModeChart==2">
        <table style="width:95%">
          <tr>
            <th>การแจ้งเตือน </th>
            <th>ปริมาณ (mm.)</th>
            <th>เวลา</th>
          </tr>
          <tr v-for="(itemlog, i) in rainlog" :key="i">
            <td>{{ itemlog.namelog }}</td>
            <td>{{ itemlog.value }}</td>
            <td>{{ itemlog.time }}</td>
          </tr>
        </table>
        <div class="text-center mt-5" v-if="rainlog==''">
          <h4 style="color: grey;">ไม่มีการแจ้งเตือน</h4>
        </div>
      </div>
    </div>
  </body>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Select date</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!-- <div>{{firstdayChart}}</div>
                    <div> {{ lastdayChart }}</div> -->
          <div class="row">
            <form class="text-center col-6 ">
              <label for="firstday">Date : </label>
              <input v-model="firstdayChart" type="date" id="firstday" name="firstday">
            </form>
            <!-- <div class="col-1 mt-4 text-center"></div> -->
            <form class="text-center col-6 ">
              <label for="lastday">Date : </label>
              <input v-model="lastdayChart" type="date" id="lastday" name="lastday">
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-dark" @click="CallchartAll('select',firstdayChart,lastdayChart)"
            data-bs-dismiss="modal">Save</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="selectChartModal" tabindex="-1" aria-labelledby="selectChartModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">

      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="selectChartModal">Select date</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!-- <div>{{firstdayChart}}</div>
                    <div> {{ lastdayChart }}</div> -->
          <div class="row">
            <form class="text-center col-6 ">
              <label for="firstday">Date : </label>
              <input v-model="firstdayChart" type="date" id="firstday" name="firstday">
            </form>
            <!-- <div class="col-1 mt-4 text-center"></div> -->
            <form class="text-center col-6 ">
              <label for="lastday">Date : </label>
              <input v-model="lastdayChart" type="date" id="lastday" name="lastday">
            </form>
          </div>
        </div>
        <!-- {{ lastdayChart }} -->

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-dark" @click="CallchartAll('select',firstdayChart,lastdayChart)"
            data-bs-dismiss="modal">Save</button>
        </div>
      </div>

    </div>
  </div>


  <div class="modal fade" id="ultrasonicModal" tabindex="-1" aria-labelledby="ultrasonicModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ultrasonicModalLabel">สอบเทียบ Sensor</h5>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body">
          <div style="font-size: 12px;">* ในการหาความลึกของน้ำจะใช้ความสูงจาก Sensor ถึงพื้นดินเพื่อลบกับค่า Sensor
            ที่วัดได้จริง</div>
          <br>
          <span>ความสูงจาก Sensor ถึงพื้นดิน </span>
          <input v-model="heightAll" placeholder="ความสูงจริง" />
          <span> เมตร</span>


          <!-- <div style="font-size: 12px;">* ในการหาความลึกของน้ำจะใช้ความสูงจาก Sensor ถึงพื้นดินเพื่อลบกับค่า Sensor
            ที่วัดได้จริง</div>
          <br> -->
          <br>
          <span>ความสูงของน้ำเพื่อแจ้ง Line &nbsp; &nbsp;</span>
          <input v-model="heightLine" placeholder="ความสูงจริง" />
          <span> เมตร</span>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" @click="putHightData()" class="btn btn-warning"
              data-bs-dismiss="modal">ยืนยัน</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  var rainchartAllColumn = [];
  var RealtimeUltrasonic = [];
  var tempChart = [];
  var humChart = [];
  var luxChart = [];
  var pm10Chart = [];
  var pm25Chart = [];
  var pressureChart = [];
  var noiseChart = [];
  var co2Chart = [];
  var rainChart = [];
  var ultrasonicChart = [];

  import checktokenAPI from "../connectBackend/checkTokenFirst";
  import {
    useMQTT
  } from 'mqtt-vue-hook'
  const mqttHook = useMQTT()
  var protocol = 'wss'
  var host = 'smsb-mqtt.smartmsbox.com'
  var port = 443

  export default {
    data() {
      return {
        // value1: true,
        // value2: true,
        firstdayChart: null,
        lastdayChart: null,
        oneTimeState: false,
        rain24: 0,
        heightAll: 0,
        heightLine: 0,
        hardwareDeviceSensorState: {
          status: false,
          ID_run: ''
        },
        hardwareDeviceCameraState: {
          status: false,
          ID_run: ''
        },
        aaa: null,
        mqtt: {
          status: false
        },
        Preload: {
          sensor: {
            "temp": false,
            "hum": false,
            "Lux": false,
            "Pressure": false,
            "Rain": false,
            "pm10": false,
            "pm25": false,
            "Co2": false,
            "Co2_2": false,
            "Ultrasonic": false,
          },
          device: {
            deviceID: null
          }
        },
        rainAll: {
          rain1d: 0,
          rain7d: 0,
          rain30d: 0,
          rain3m: 0,
          rain6m: 0,
          rain1y: 20
        },
        rainlog: [],
        load: {
          sensor: {
            "temp": null,
            "hum": null,
            "Lux": null,
            "Pressure": null,
            "Rain": null,
            "pm10": null,
            "pm25": null,
            "Co2": null,
            "Co2_2": null,
            "Ultrasonic": null
          },
          device: {
            deviceID: null
          }

        },
        getChartParameter: {
          sn: '',
          mode: '',
          timeStart: '',
          timeEnd: ''
        },
        rtspUrl: process.env.VUE_APP_RTSP_URL,
        cctvListall: [],
        cctvListalluse: [],
        id: '',
        infolocation: {
          name: '',
          Province: '',
          Zipcode: '',
          Zone: "",
          status: false,
          lat: '',
          lon: ''
        },
        selectedCCTV: 1,
        selectedModeChart: 0,

        series_all_temp: [{
          name: "Temp (.C)",
          data: tempChart.slice()
        }],

        chartOptions_all_temp: {
          chart: {
            height: 380,
            width: "100%",
            type: 'line',
            foreColor: '#000000',

            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                customIcons: []
              },
              export: {
                csv: {
                  filename: 'AllSensor',
                  columnDelimiter: ',',
                  headerCategory: 'Time',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp)
                  }
                },
                svg: {
                  filename: 'AllSensor',
                },
                png: {
                  filename: 'AllSensor',
                }
              },
              autoSelected: 'zoom'
            },


          },

          colors: ['#EEE264', '#545454', '#70569E', ],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 0.5,
            dashArray: 0,
          },
          xaxis: {
            type: 'datetime',
            /* range: XAXISRANGE, */
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm:ss',
              }
            },
          },
          yaxis: {
            decimalsInFloat: 2
          },
          tooltip: {
            x: {
              show: true,
              format: 'dd MMM yy HH:mm:ss',
              formatter: undefined,
            }
          },
        },

        series_all_hum: [{
          name: "hum (%RH)",
          data: humChart.slice()
        }],
        chartOptions_all_hum: {
          chart: {
            height: 380,
            width: "100%",
            type: 'line',
            foreColor: '#000000',

            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                customIcons: []
              },
              export: {
                csv: {
                  filename: 'AllSensor',
                  columnDelimiter: ',',
                  headerCategory: 'Time',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp)
                  }
                },
                svg: {
                  filename: 'AllSensor',
                },
                png: {
                  filename: 'AllSensor',
                }
              },
              autoSelected: 'zoom'
            },


          },

          colors: ['#EEE264', '#545454', '#70569E', ],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 0.5,
            dashArray: 0,
          },
          xaxis: {
            type: 'datetime',
            /* range: XAXISRANGE, */
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm:ss',
              }
            },
          },
          yaxis: {
            decimalsInFloat: 2
          },
          tooltip: {
            x: {
              show: true,
              format: 'dd MMM yy HH:mm:ss',
              formatter: undefined,
            }
          },
        },

        series_all_lux: [{
          name: "lux (%RH)",
          data: luxChart.slice()
        }],
        chartOptions_all_lux: {
          chart: {
            height: 380,
            width: "100%",
            type: 'line',
            foreColor: '#000000',

            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                customIcons: []
              },
              export: {
                csv: {
                  filename: 'AllSensor',
                  columnDelimiter: ',',
                  headerCategory: 'Time',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp)
                  }
                },
                svg: {
                  filename: 'AllSensor',
                },
                png: {
                  filename: 'AllSensor',
                }
              },
              autoSelected: 'zoom'
            },


          },

          colors: ['#EEE264', '#545454', '#70569E', ],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 0.5,
            dashArray: 0,
          },
          xaxis: {
            type: 'datetime',
            /* range: XAXISRANGE, */
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm:ss',
              }
            },
          },
          yaxis: {
            decimalsInFloat: 2
          },
          tooltip: {
            x: {
              show: true,
              format: 'dd MMM yy HH:mm:ss',
              formatter: undefined,
            }
          },
        },


        series_all_pm10: [{
          name: "pm10 (%RH)",
          data: pm10Chart.slice()
        }],
        chartOptions_all_pm10: {
          chart: {
            height: 380,
            width: "100%",
            type: 'line',
            foreColor: '#000000',

            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                customIcons: []
              },
              export: {
                csv: {
                  filename: 'AllSensor',
                  columnDelimiter: ',',
                  headerCategory: 'Time',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp)
                  }
                },
                svg: {
                  filename: 'AllSensor',
                },
                png: {
                  filename: 'AllSensor',
                }
              },
              autoSelected: 'zoom'
            },


          },

          colors: ['#EEE264', '#545454', '#70569E', ],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 0.5,
            dashArray: 0,
          },
          xaxis: {
            type: 'datetime',
            /* range: XAXISRANGE, */
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm:ss',
              }
            },
          },
          yaxis: {
            decimalsInFloat: 2
          },
          tooltip: {
            x: {
              show: true,
              format: 'dd MMM yy HH:mm:ss',
              formatter: undefined,
            }
          },
        },

        series_all_pm25: [{
          name: "pm25 (%RH)",
          data: pm25Chart.slice()
        }],
        chartOptions_all_pm25: {
          chart: {
            height: 380,
            width: "100%",
            type: 'line',
            foreColor: '#000000',

            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                customIcons: []
              },
              export: {
                csv: {
                  filename: 'AllSensor',
                  columnDelimiter: ',',
                  headerCategory: 'Time',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp)
                  }
                },
                svg: {
                  filename: 'AllSensor',
                },
                png: {
                  filename: 'AllSensor',
                }
              },
              autoSelected: 'zoom'
            },


          },

          colors: ['#EEE264', '#545454', '#70569E', ],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 0.5,
            dashArray: 0,
          },
          xaxis: {
            type: 'datetime',
            /* range: XAXISRANGE, */
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm:ss',
              }
            },
          },
          yaxis: {
            decimalsInFloat: 2
          },
          tooltip: {
            x: {
              show: true,
              format: 'dd MMM yy HH:mm:ss',
              formatter: undefined,
            }
          },
        },

        series_all_pressure: [{
          name: "pressure (Kpa)",
          data: pressureChart.slice()
        }],
        chartOptions_all_pressure: {
          chart: {
            height: 380,
            width: "100%",
            type: 'line',
            foreColor: '#000000',

            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                customIcons: []
              },
              export: {
                csv: {
                  filename: 'AllSensor',
                  columnDelimiter: ',',
                  headerCategory: 'Time',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp)
                  }
                },
                svg: {
                  filename: 'AllSensor',
                },
                png: {
                  filename: 'AllSensor',
                }
              },
              autoSelected: 'zoom'
            },


          },

          colors: ['#EEE264', '#545454', '#70569E', ],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 0.5,
            dashArray: 0,
          },
          xaxis: {
            type: 'datetime',
            /* range: XAXISRANGE, */
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm:ss',
              }
            },
          },
          yaxis: {
            decimalsInFloat: 2
          },
          tooltip: {
            x: {
              show: true,
              format: 'dd MMM yy HH:mm:ss',
              formatter: undefined,
            }
          },
        },


        series_all_noise: [{
          name: "noise (dB)",
          data: noiseChart.slice()
        }],
        chartOptions_all_noise: {
          chart: {
            height: 380,
            width: "100%",
            type: 'line',
            foreColor: '#000000',

            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                customIcons: []
              },
              export: {
                csv: {
                  filename: 'AllSensor',
                  columnDelimiter: ',',
                  headerCategory: 'Time',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp)
                  }
                },
                svg: {
                  filename: 'AllSensor',
                },
                png: {
                  filename: 'AllSensor',
                }
              },
              autoSelected: 'zoom'
            },


          },

          colors: ['#EEE264', '#545454', '#70569E', ],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 0.5,
            dashArray: 0,
          },
          xaxis: {
            type: 'datetime',
            /* range: XAXISRANGE, */
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm:ss',
              }
            },
          },
          yaxis: {
            decimalsInFloat: 2
          },
          tooltip: {
            x: {
              show: true,
              format: 'dd MMM yy HH:mm:ss',
              formatter: undefined,
            }
          },
        },

        series_all_rain: [{
          name: "rain (mm)",
          data: rainChart.slice()
        }],
        chartOptions_all_rain: {
          chart: {
            height: 380,
            width: "100%",
            type: 'line',
            foreColor: '#000000',

            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                customIcons: []
              },
              export: {
                csv: {
                  filename: 'AllSensor',
                  columnDelimiter: ',',
                  headerCategory: 'Time',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp)
                  }
                },
                svg: {
                  filename: 'AllSensor',
                },
                png: {
                  filename: 'AllSensor',
                }
              },
              autoSelected: 'zoom'
            },


          },

          colors: ['#EEE264', '#545454', '#70569E', ],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 0.5,
            dashArray: 0,
          },
          xaxis: {
            type: 'datetime',
            /* range: XAXISRANGE, */
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm:ss',
              }
            },
          },
          yaxis: {
            decimalsInFloat: 2
          },
          tooltip: {
            x: {
              show: true,
              format: 'dd MMM yy HH:mm:ss',
              formatter: undefined,
            }
          },
        },

        series_all_co2: [{
          name: "Co2 ",
          data: co2Chart.slice()
        }],
        chartOptions_all_co2: {
          chart: {
            height: 380,
            width: "100%",
            type: 'line',
            foreColor: '#000000',

            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                customIcons: []
              },
              export: {
                csv: {
                  filename: 'AllSensor',
                  columnDelimiter: ',',
                  headerCategory: 'Time',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp)
                  }
                },
                svg: {
                  filename: 'AllSensor',
                },
                png: {
                  filename: 'AllSensor',
                }
              },
              autoSelected: 'zoom'
            },


          },

          colors: ['#EEE264', '#545454', '#70569E', ],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 0.5,
            dashArray: 0,
          },
          xaxis: {
            type: 'datetime',
            /* range: XAXISRANGE, */
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm:ss',
              }
            },
          },
          yaxis: {
            decimalsInFloat: 2
          },
          tooltip: {
            x: {
              show: true,
              format: 'dd MMM yy HH:mm:ss',
              formatter: undefined,
            }
          },
        },

        series_all_ultrasonic: [{
          name: "ultrasonic ",
          data: ultrasonicChart.slice()
        }],
        chartOptions_all_ultrasonic: {
          chart: {
            height: 380,
            width: "100%",
            type: 'line',
            foreColor: '#000000',

            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                customIcons: []
              },
              export: {
                csv: {
                  filename: 'AllSensor',
                  columnDelimiter: ',',
                  headerCategory: 'Time',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp)
                  }
                },
                svg: {
                  filename: 'AllSensor',
                },
                png: {
                  filename: 'AllSensor',
                }
              },
              autoSelected: 'zoom'
            },


          },

          colors: ['#EEE264', '#545454', '#70569E', ],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 0.5,
            dashArray: 0,
          },
          xaxis: {
            type: 'datetime',
            /* range: XAXISRANGE, */
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm:ss',
              }
            },
          },
          yaxis: {
            decimalsInFloat: 2
          },
          tooltip: {
            x: {
              show: true,
              format: 'dd MMM yy HH:mm:ss',
              formatter: undefined,
            }
          },
        },

        seriesUltrasonic: [{
          name: 'Ultrasobic',
          data: RealtimeUltrasonic.slice()
        }],
        chartOptionsUltrasonic: {
          colors: ["#ffc107"],
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },

          xaxis: {
            categories: ["Ultrasonic"],
            position: 'top',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 200],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            max: 100,
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "%";
              }
            }

          },
          title: {
            text: 'Sensor Data',
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
              color: '#444'
            }
          }
        },

        series_column_rain: [{
          name: 'rain',
          data: rainchartAllColumn.slice()
        }],
        chartOptions_column_rain: {
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          // dataLabels: {
          //   enabled: true,
          //   formatter: function (val) {
          //     return val + "mm";
          //   },
          //   offsetY: -20,
          //   style: {
          //     fontSize: '12px',
          //     colors: ["#304758"]
          //   }
          // },

          xaxis: {
            // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            position: 'bottom',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            // tooltip: {
            //   enabled: true,
            // },
            labels: {
              datetimeUTC: true,
              datetimeFormatter: {
                // year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm:ss',
              }
            },
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "mm";
              }
            }

          },
          title: {
            text: '',
            floating: true,
            offsetY: 330,
            align: 'center',
            style: {
              color: '#444'
            }
          }
        },





      };
    },
    watch: {
      id(newId) {
        // Update the src link when id changes
        this.updateIframeSrc(newId);
      },
    },
    computed: {
      iframeSrc() {
        // Compute the src link based on the current id value
        return `http://localhost:2000/${this.id}`;
      },
    },
    methods: {
      async putHightData() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/device/config", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
          },
          body: JSON.stringify({
            SN: this.hardwareDeviceSensorState.ID_run,
            calibrate_ultrasonic: this.heightAll,
            alarm_ultrasonic: this.heightLine
          }),
        });
        var data = response;
        console.log(data.status);
        // if (data.status == 200) {

        // }
      },
      async getHightData() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/device/config/" + this
          .hardwareDeviceSensorState.ID_run, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("token_admin"),
            },
          });
        var data = await response.json();
        this.heightAll = data.calibrate_ultrasonic;
        this.heightLine = data.alarm_ultrasonic;
        // this.ListAreaItem = await data;
        console.log(data);
      },

      async getLastRainData() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/device/rain/config/" + this
          .hardwareDeviceSensorState.ID_run, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("token_admin"),
            },
          });
        var data = await response.json();
        // this.heightAll = data.calibrate_ultrasonic;
        // this.heightLine = data.alarm_ultrasonic;
        // this.ListAreaItem = await data;
        this.rain24 = data.rain;
        // console.log(data);
      },



      async getAllRainData() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/rain/log", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
            "x-requested-sn": await this.hardwareDeviceSensorState.ID_run
          },
        });
        var data = await response.json();
        console.log(data);
        this.rainAll = data;

      },

      async getRainLogData() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/log/device/alarm", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
            "x-requested-sn": this.hardwareDeviceSensorState.ID_run,
          },
        });
        var data = await response.json();
        this.rainlog = data;

      },

      formatFloat(number, decimalPlaces) {
        var myload=0;
        if(typeof number == 'number'){
          myload= number.toFixed(decimalPlaces);
        }else{
          myload = "not detected"
        }
        // Ensure number is a valid floating-point number
        // if (typeof flaot !== 'number') {
        //   return 'Invalid Number';
        // }

        // Use toFixed to format the number with the specified decimal places
        return myload;
      },
      async getDeviceByID() {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/devices/map", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
          },
        });
        var load = localStorage.getItem("infolocations");
        var arr2 = load.split(",");
        var idOfdevice = parseInt(arr2[0], 10);

        var data = await response.json();

        for (let index = 0; index < data.length; index++) {
          if (data[index].Namebox.id == idOfdevice) {
            this.aaa = data[index].Namebox.id;
            this.cctvListall = data[index].device;

          }
        }
        console.log(this.aaa)
        console.log(data)
        for (let index = 0; index < this.cctvListall.length; index++) {
          if (this.cctvListall[index].type == "weather-001") {
            this.hardwareDeviceSensorState.status = true;
            this.hardwareDeviceSensorState.ID_run = this.cctvListall[index].RN;
          } else if (this.cctvListall[index].type == "Camera" || this.cctvListall[index].type == "camera") {
            this.hardwareDeviceCameraState.status = true;
            this.cctvListalluse.push(this.cctvListall[index])
          }

        }
        console.log(this.cctvListall)

        mqttHook.connect(`${protocol}://${host}:${port}/ws`, {
            username: 'qqq',
            password: 'qqq',
            // clean: false,
            keepalive: 60,
            // clientId: `mqtt_client_${Math.random().toString(16).substring(2, 10)}`,
            connectTimeout: 4000,
          }),
          mqttHook.subscribe(
            ['@msg/real/SJPIOT001/' + this.hardwareDeviceSensorState.ID_run],
            1, {
              properties: {
                // userProperties: {...}
              },
            },
            () => {

              console.log('subscribed!')
              this.getHightData();
            }
          )

        mqttHook.registerEvent(
          '@msg/real/SJPIOT001/' + this.hardwareDeviceSensorState.ID_run,
          (_topic, message) => {

            // console.log(message)
            const messageString = message.toString('utf8');

            try {
              // Parse the string as JSON
              const jsonObject = JSON.parse(messageString);
              this.mqtt.status = true;
              // Now you can work with the JSON object
              // console.log(jsonObject);


              // console.log("on tets")
              // if(jsonObject){

              // }

              this.load = jsonObject;
              RealtimeUltrasonic = [((Math.abs((this.heightAll - ((this.load["sensor"]["Ultrasonic"]) / 100))
                .toFixed(2))) / this.heightAll) * 100];

              // this.$refs.updateRealtimeUltra.updateSeries([{
              //   data:  [10] ,
              // }]);
            } catch (error) {
              this.mqtt.status = false;
              console.error("Error parsing the message as JSON:", error);
            }
            this.$refs.updateRealtimeUltra.updateSeries([{
              data: RealtimeUltrasonic,
            }]);
            // callback
          },
          // 'string_key',
          'mqtt-client-2', // clientID
        )
      },
      updateIframeSrc(newId) {
        // Compute and set the new src link
        this.$refs.iframe.src = `http://localhost:2000/${newId}`;
      },
      callINFO_Location() {
        var load = localStorage.getItem("infolocations");
        var arr = load.split(",");
        console.log(arr[1]);
        this.id = arr[0];
        this.infolocation.name = arr[1];
        this.infolocation.Zone = arr[2];
        this.infolocation.Province = arr[3];
        this.infolocation.status = arr[4];
        this.infolocation.Zipcode = arr[5];
        this.infolocation.lat = arr[6]
        this.infolocation.lon = arr[7]
      },

      // async CallChartRain(mode, c_timeStart, c_timeEnd){
      //   const response = await fetch(process.env.VUE_APP_API_URL + "/api/graph", {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/json",
      //       "x-access-token": localStorage.getItem("token_admin"),
      //       "x-requested-sn": this.hardwareDeviceSensorState.ID_run,
      //       "x-requested-mode": mode,
      //       "timestart": c_timeStart,
      //       "timeend": c_timeEnd
      //     },
      //   });
      // },
      async CallchartAll(mode, c_timeStart, c_timeEnd) {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/graph", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
            "x-requested-sn": this.hardwareDeviceSensorState.ID_run,
            "x-requested-mode": mode,
            "timestart": c_timeStart,
            "timeend": c_timeEnd
          },
        });
        var data = await response.json();
        // console.log( c_timeStart);
        // console.log( c_timeEnd)

        const responseRain = await fetch(process.env.VUE_APP_API_URL + "/api/rain/graph", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token_admin"),
            "x-requested-sn": this.hardwareDeviceSensorState.ID_run,
            "x-requested-mode": mode,
            "timestart": c_timeStart,
            "timeend": c_timeEnd
          },
        });
        var dataRain = await responseRain.json();
        // console.log("data...............");
        // console.log(dataRain);
        rainchartAllColumn = [];
        for (let index = 0; index < dataRain.length; index++) {
          rainchartAllColumn.push({
            x: dataRain[index]["time_b"],
            y: dataRain[index]["rainday"]
          });
        }
        try {
          this.$refs.updateRainAllColumn.updateSeries([{
            data: rainchartAllColumn
          }]);
          console.log(rainchartAllColumn);
        } catch (error) {
          console.log(rainchartAllColumn);
        }




      
        rainChart = [];
        tempChart = [];
        humChart = [];
        luxChart = [];
        pm10Chart = [];
        pm25Chart = [];
        co2Chart = [];
        pressureChart = [];
        noiseChart = [];
        ultrasonicChart = [];
        for (let index = 0; index < data.length; index++) {
          tempChart.push([data[index]["time_b"], data[index]["temp"]]);
          humChart.push([data[index]["time_b"], data[index]["hum"]]);
          luxChart.push([data[index]["time_b"], data[index]["lux"]]);
          pm10Chart.push([data[index]["time_b"], data[index]["pm10"]]);
          pm25Chart.push([data[index]["time_b"], data[index]["pm25"]]);
          pressureChart.push([data[index]["time_b"], data[index]["pressure"]]);
          noiseChart.push([data[index]["time_b"], data[index]["noise"]]);
          rainChart.push([data[index]["time_b"], data[index]["rain"]]);
          co2Chart.push([data[index]["time_b"], data[index]["co2"]]);
          ultrasonicChart.push([data[index]["time_b"], data[index]["ultrasonic"]]);
        }
        // console.log(tempChart)
        try {
          this.$refs.updatetempAll.updateSeries([{
            data: tempChart
          }]);
        } catch (error) {
          console.log("error")
        }

        try {
          this.$refs.updatehumAll.updateSeries([{
            data: humChart
          }]);
        } catch (error) {
          console.log("error")
        }

        try {

          this.$refs.updateluxAll.updateSeries([{
            data: luxChart
          }]);
        } catch (error) {
          console.log("error")
        }

        try {

          this.$refs.updatepm10All.updateSeries([{
            data: pm10Chart
          }]);
        } catch (error) {
          console.log("error")
        }

        try {

          this.$refs.updatepm25All.updateSeries([{
            data: pm25Chart
          }]);
        } catch (error) {
          console.log("error")
        }

        try {

          this.$refs.updatepressureAll.updateSeries([{
            data: pressureChart
          }]);
        } catch (error) {
          console.log("error")
        }


        try {

          this.$refs.updatenoiseAll.updateSeries([{
            data: noiseChart
          }]);
        } catch (error) {
          console.log("error")
        }


        try {

          this.$refs.updaterainAll.updateSeries([{
            data: rainChart
          }]);
        } catch (error) {
          console.log("error")
        }

        try {

          this.$refs.updateco2All.updateSeries([{
            data: co2Chart
          }]);
        } catch (error) {
          console.log("error")
        }

        try {
          this.$refs.updateultrasonicAll.updateSeries([{
            data: ultrasonicChart
          }]);
        } catch (error) {
          console.log("error")
        }

      },
      checkChart() {
        if (this.selectedModeChart == 1) {

          if (this.oneTimeState == false) {
            this.oneTimeState = true;
            this.CallchartAll("1hr", "", "");
          }
        }
      }
    },
    mounted() {
      // this.getLastRainData();
      // this.getHightData();
    },
    created() {

      checktokenAPI.check();

      // MQTT connection options
      // const options = {
      //     username: 'qqq',
      //     password: 'qqq',
      //     // clientId: 'your_client_id', // Optional
      //     protocol: 'ws', // Change this based on your broker's protocol
      //     host:'sjp.sgroup.io/ws', // Change this to your MQTT broker's address
      //     port: 15675, // Change this to your MQTT broker's port
      //     keepalive: 60,
      //     connectTimeout: 4000,
      //   };



      this.callINFO_Location();
      this.getDeviceByID().then(() => {
        this.getLastRainData();
        this.getAllRainData();
        this.getRainLogData();
        // this.CallchartAll('1hr','','');
      });

    },
    beforeMount() {
      // this.getDeviceByID()
    },
    unmounted() {
      mqttHook.unSubscribe('@msg/sg001/' + this.hardwareDeviceSensorState.ID_run)
      mqttHook.unRegisterEvent('@msg/sg001/' + this.hardwareDeviceSensorState.ID_run)
    }
    //   createConnection() {

    //   // Connect to MQTT broker
    //   mqttHook.connect(`${protocol}://${host}:${port}/ws`,{
    //     username:'qqq',
    // password:'qqq',
    //   // clean: false,
    //   keepalive: 60,
    //   // clientId: `mqtt_client_${Math.random().toString(16).substring(2, 10)}`,
    //   connectTimeout: 4000,
    //   }).then(() => {
    //     console.log('Connected to MQTT broker');

    //     // Subscribe to a topic
    //     mqttHook.subscribe('@msg', { qos: 0 }, (topic, payload) => {
    //       console.log(`Received message on topic ${topic}: ${payload}`);
    //     });
    //   });
    //   }
    //   beforeDestroy() {
    //   // Disconnect from MQTT broker when the component is destroyed
    //   mqttHook.disconnect();
    // },
  };
</script>
<script setup>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    loadPlayer
  } from 'rtsp-relay/browser';

  const canvas = ref(null);

  onMounted(() => {
    loadPlayer({
      url: 'ws://localhost:2000/api/stream',
      canvas: canvas.value,
    });
  });
</script>
<style>
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
</style>