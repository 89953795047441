<template>
    <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="text-center text-success">
                        สร้างผู้ใช้งานสำเร็จ
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal Adduser -->
    <div class="modal fade" id="adduserModal" tabindex="-1" aria-labelledby="adduserModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="adduserModalLabel">+ สร้างเขตพื้นที่</h5>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                </div>
                <div class="modal-body" v-if="timeoutLoginState==true">
                    <div class="card-body px-5" style="width: 100%;">
                        <img src="../../assets/logo.png" alt=""
                            style="width: 350px;">
                        <!-- 2 column grid layout with text inputs for the first and last names -->
                        <h5 class="mt-2 mb-3 text-center" style="width: 100%;">
                            เข้าสู่ระบบอีกครั้ง
                        </h5>

                        <!-- Email input -->
                        <div class="form-outline mb-4">
                            <input type="text" v-model="insideLogin.username" class="form-control" />
                            <label class="form-label" for="form3Example3">Username</label>
                        </div>

                        <!-- Password input -->
                        <div class="form-outline mb-4">
                            <input type="password" v-model="insideLogin.password" class="form-control" />
                            <label class="form-label">Password</label>
                        </div>
                        <div class="text-center mb-2 text-danger">{{ insideLogin.ErrorMessage }}</div>
                        <!-- Submit button -->
                        <div class="text-center">
                            <button type="submit" class="btn btn-warning btn-block mb-4 text-white" @click="reloginWhenTimeOut()"
                                style="width: 200px;">
                                เข้าสู่ระบบ
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body" v-if="timeoutLoginState==false">
                    <div class="">
                        <div>
                            <span>ชื่อพื้นที่ : </span><input v-model="addnewarea.namearea" placeholder="ชื่อพื้นที่"
                                style="width: 100%; height: 35px;" />
                        </div>
                        <div>
                            <span>Line Token : </span><input v-model="addnewarea.line_token" placeholder="Line Token"
                                style="width: 100%; height: 35px;" />
                        </div>
                        <div class=" mt-2">
                            <span>ตำแหน่งที่ตั้ง : </span>
                            <input v-model="addnewarea.lat" placeholder="lat" style="width: 30%; height: 35px;" />
                            &nbsp;
                            <input v-model="addnewarea.lon" placeholder="lon" style="width: 30%; height: 35px;" />
                        </div>
                    </div>
                </div>
                <div class="text-start ms-3" style="font-size: 14px; color: red;">
                        {{ nofild }}
                    </div>
                <div class="modal-footer">
                  
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="nofild=''">Close</button>
                 
                    <button v-if="timeoutLoginState==false&&addnewarea.namearea!=''&& addnewarea.line_token!='' && addnewarea.lat!='' && addnewarea.lon!= '' " type="button" class="btn btn-warning" @click="addAreaByAdmin(),nofild=''" data-bs-dismiss="modal">
                        {{ nofild="" }}+สร้าง</button>
                    <button v-else type="button" class="btn btn-light" @click="nofild='กรุณากรอกข้อมูลให้ครบ'" >
                        
                        +สร้าง</button>
                </div>
            </div>
        </div>
    </div>

        <!-- Modal Edit user -->
        <div class="modal fade" id="edituserModal" tabindex="-1" aria-labelledby="edituserModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="edituserModalLabel">แก้ไขเขตพื้นที่</h5>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                </div>
                <div class="modal-body" v-if="timeoutLoginState==true">
                    <div class="card-body px-5" style="width: 100%;">
                        <img src="../../assets/logo.png" alt=""
                            style="width: 350px;">
                        <!-- 2 column grid layout with text inputs for the first and last names -->
                        <h5 class="mt-2 mb-3 text-center" style="width: 100%;">
                            เข้าสู่ระบบอีกครั้ง
                        </h5>

                        <!-- Email input -->
                        <div class="form-outline mb-4">
                            <input type="text" v-model="insideLogin.username" class="form-control" />
                            <label class="form-label" for="form3Example3">Username</label>
                        </div>

                        <!-- Password input -->
                        <div class="form-outline mb-4">
                            <input type="password" v-model="insideLogin.password" class="form-control" />
                            <label class="form-label">Password</label>
                        </div>
                        <div class="text-center mb-2 text-danger">{{ insideLogin.ErrorMessage }}</div>
                        <!-- Submit button -->
                        <div class="text-center">
                            <button type="submit" class="btn btn-warning btn-block mb-4 text-white" @click="reloginWhenTimeOut()"
                                style="width: 200px;">
                                เข้าสู่ระบบ
                            </button>
                        </div>
                    </div>
                </div>
                <!-- {{ itemCallZone }} -->
                <div class="modal-body" v-if="timeoutLoginState==false">
                    <div class="">
                        <div>
                            <!-- {{ itemCallZone }} -->
                            <span>ชื่อพื้นที่ : </span><input v-model="itemCallZone.name" placeholder="ชื่อพื้นที่"
                                style="width: 100%; height: 35px;" />
                        </div>
                        <div>
                            <span>Line Token : </span><input v-model="itemCallZone.lineTokens" placeholder="Line Token"
                                style="width: 100%; height: 35px;" />
                        </div>
                        <div class=" mt-2">
                            <span>ตำแหน่งที่ตั้ง : </span>
                            <input v-model="itemCallZone.latitude" placeholder="lat" style="width: 30%; height: 35px;" />
                            &nbsp;
                            <input v-model="itemCallZone.longitude" placeholder="lon" style="width: 30%; height: 35px;" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button v-if="timeoutLoginState==false" type="button" class="btn btn-warning" @click="editAreaByAdmin()" data-bs-dismiss="modal">
                        แก้ไข</button>
                </div>
            </div>
        </div>
    </div>


    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/supperadmin/landing">
        <img src="../../assets/logo.png" style="width: 250px;" alt="">
      </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <router-link to="/supperadmin/map" style="text-decoration: none;">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="#">อุปกรณ์บนแผนที่</a>
                        </li>
                    </router-link>
                    <router-link to="/supperadmin/area" style="text-decoration: none;">
                        <li class="nav-item">
                            <a class="nav-link text-warning" aria-current="page" href="#">เขตพื้นที่</a>
                        </li>
                    </router-link>
                    <router-link to="/supperadmin/adduser" style="text-decoration: none;">
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#">ผู้ใช้งาน</a>
                        </li>
                    </router-link>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            อุปกรณ์
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li>
                                <router-link to="/supperadmin/detailnamebox" style="text-decoration: none;"><a
                                        class="dropdown-item" href="">รายละเอียดกล่อง</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/supperadmin/detaildevice" style="text-decoration: none;"><a
                                        class="dropdown-item" href="">รายละเอียดที่ตั้งอุปกรณ์</a></router-link>
                            </li>
                            <li>
                                <router-link to="/supperadmin/detailhardware" style="text-decoration: none;"><a
                                        class="dropdown-item" href="#">รายละเอียดรุ่นอุปกรณ์</a></router-link>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li>
                <router-link to="/supperadmin/statusdevice" style="text-decoration: none;"><a class="dropdown-item"
                    href="#">รายงานอุปกรณ์ออฟไลน์</a></router-link>
              </li>
                            <!-- <li><a class="dropdown-item" href="#">รายงานอุปกรณ์ออฟไลน์</a></li> -->
                        </ul>
                    </li>
                </ul>
                <form class="d-flex">
                    <div class="col-12  text-end">
                        <button class="btn btn-outline-warning ms-2" type="button" data-bs-toggle="modal"
                            data-bs-target="#adduserModal" @click="timeOut()">+ สร้างเขตพื้นที่</button>
                    </div>

                </form>
            </div>
        </div>
    </nav>

    <body>
        <div>
            <div v-if="showPopup" class="popup rounded ">
                <font-awesome-icon icon="fa-solid fa-circle-check" style="font-size: 80px;color:#32CD32;" />
                <p class="text-center mt-2">สำเร็จ</p>
            </div>
        </div>
        <div style="overflow-x:auto;">
            <table class="">

                <tr>


                    <th style="min-width: 150px;">ชื่อพื้นที่</th>
                    <th style="min-width: 200px;">ตำแหน่งที่ตั้ง</th>
                    <th style="min-width: 100px;">วันที่สร้าง</th>
                    <th style="min-width: 100px;">แก้ไข</th>
                </tr>

                <tr v-for="(AreaItem, i) in ListAreaItem" :key="i">
                    <td>{{ AreaItem.name }}</td>
                    <td>{{ AreaItem.latitude }}, {{ AreaItem.longitude }}</td>
                    <td>{{ convertTime(AreaItem.createdAt) }}</td>
                    <td class="text-start" >
                         <span>
                            <font-awesome-icon class="btn" type="button" data-bs-toggle="modal"
                            data-bs-target="#edituserModal" @click="timeOut(),itemCallZone=AreaItem" style="color: skyblue;" icon="fa-solid fa-pen" /> </span> 
                            
                            <span
                            class="ms-3" >
                            <font-awesome-icon class="btn" type="button" @click="delAreaByID(AreaItem.id)" style="color: #FFBBAC;" icon="fa-solid fa-box-archive" /></span>
                        
                        </td>

                </tr>
            </table>
        </div>

    </body>

</template>
<script>
import checktokenAPI from "../connectBackend/checkTokenFirst";
   import CheckTimeService from '../timeVerify/timeoutVerify'
    export default {


        data() {
            return {
                itemCallZone:{ id: 0, name: "", lineTokens: "", latitude: 0, longitude: 0, createdAt: "2023-11-07T03:20:44.882Z", updatedAt: "2023-11-07T03:20:44.882Z" },
                nofild:'',
                timeoutLoginState: false,
                showPopup: false,
                addnewarea: {
                    namearea: '',
                    lat: '',
                    lon: '',
                    line_token: ''
                },
                insideLogin: {
                    username: '',
                    password: '',
                    ErrorMessage:''
                },
                ListAreaItem: [],
            };
        },
        methods: {
            timeOut() {
                var statusTimeout = CheckTimeService.checkTimeOut();
                this.timeoutLoginState = statusTimeout;
            },
            
            convertTime(timeIn) {
                const date = new Date(timeIn);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear().toString();
                const formattedDate = `${day}/${month}/${year}`
                return formattedDate;
            },
            async addAreaByAdmin() {
                const response = await fetch(process.env.VUE_APP_API_URL+"/api/zone/add", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    },
                    body: JSON.stringify({
                        namezone: this.addnewarea.namearea,
                        latitude: this.addnewarea.lat,
                        longitude: this.addnewarea.lon,
                        lineToken: this.addnewarea.line_token
                    }),
                });
                var data = await response;
                console.log(data.status);
                if (data.status == 200) {
                    this.showPopupAfterDelay();
                    this.getAreaAll();
                }
            },

            async editAreaByAdmin() {
                const response = await fetch(process.env.VUE_APP_API_URL+"/api/zone/update", {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    },
                    body: JSON.stringify({
                        id:this.itemCallZone.id,
                        namezone: this.itemCallZone.name,
                        latitude: this.itemCallZone.latitude,
                        longitude: this.itemCallZone.latitude,
                        lineToken: this.itemCallZone.lineTokens
                    }),
                });
                var data = await response;
                console.log(data.status);
                if (data.status == 200) {
                    this.showPopupAfterDelay();
                    this.getAreaAll();
                }
            },

            async getAreaAll() {
                const response = await fetch(process.env.VUE_APP_API_URL+"/api/zones/all", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    },
                });
                var data = await response.json();
                this.ListAreaItem = await data;
                console.log(data);
            },

            async gettestBody() {
                const response = await fetch("https://testsjp.iotndev.com/api/graph", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTgsImlhdCI6MTY5ODExNjY3MiwiZXhwIjoxNjk4MjAzMDcyfQ.yMspQx1Uh8qqCXpS57VjA7bpPx2GSyj2C_lo2nj8_Uo",
                        "sn": "SND4D4DA5BB870",
                        "mode": "1hr",
                        "timeStart":"",
                        "timeEnd":"" 
                    },
                });
                var data = await response.json();
                // this.ListAreaItem = await data;
                console.log(data);
            },

            

            async delAreaByID(id) {
                const response = await fetch(process.env.VUE_APP_API_URL+"/api/zone/delete/"+id, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token_admin"),
                    },
                });
                // var data = await response.json();
                if(response.status==200){
                    this.getAreaAll();
                }
            },

            showPopupAfterDelay() {
                this.showPopup = true;
                setTimeout(() => {
                    this.showPopup = false;
                }, 1000);
            },
            async reloginWhenTimeOut() {

const response = await fetch(process.env.VUE_APP_API_URL+"/api/auth/signin", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify({
        username: this.insideLogin.username,
        password: this.insideLogin.password
    }),
});
var data = await response.json();
if (data.accessToken == null) {
    this.ErrorMessage = data.message;
} else {
    this.insideLogin.ErrorMessage = "";
    if (data.roles == "ROLE_admin") {
        localStorage.setItem("token_admin", data.accessToken);
        localStorage.removeItem("token_user");
        localStorage.setItem("verify_time", Date.now());
        this.timeOut();
        location.reload();
    } else if (data.roles == "ROLE_user") {
        this.ErrorMessage = "Role Error";
    }
}
console.log(data);
} 
        },
        created() {
            checktokenAPI.check();
            this.getAreaAll();
            this.gettestBody();
            // this.myFunction();
        }
    };
</script>

<style>
    .popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255, 255, 255, 0);
        padding: 20px;
        /* border: 1px solid black; */
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #dddddd;
    }
</style>