import { createRouter, createWebHistory } from 'vue-router'
import login from '../components/login';
//supperadmin router
import sup_admin_adduser from '../components/page_supperadmin/adduser'
import sup_admin_area from '../components/page_supperadmin/area'
import sup_admin_map from '../components/page_supperadmin/mappage'
import sup_admin_detaildevice from '../components/page_supperadmin/detaildevice'
import sup_admin_detailhardware from '../components/page_supperadmin/detailhardware'
import sup_admin_detailnamebox from '../components/page_supperadmin/detailnamebox'
import sup_admin_overall from '../components/page_supperadmin/overall'
import sup_admin_landing_overall from '../components/page_supperadmin/landdingoverall'
import sup_admin_statusdevice from '../components/page_supperadmin/statusdevice' 

//zoneadmin router

import zone_admin_adduser from '../components/page_admin_zone/adduser'
import zone_admin_map from '../components/page_admin_zone/mappage'
import zone_admin_detaildevice from '../components/page_admin_zone/detaildevice'
import zone_admin_detailhardware from '../components/page_admin_zone/detailhardware'
import zone_admin_detailnamebox from '../components/page_admin_zone/detailnamebox'
import zone_admin_overall from '../components/page_admin_zone/overall'
import zone_admin_landing_overall from '../components/page_admin_zone/landdingoverall'
import zone_admin_statusdevice from '../components/page_admin_zone/statusdevice' 

// user zone
import usermap from '../components/page_user_zone/mappage'
import useroverall from '../components/page_user_zone/overall'
// import indexAdmin from '../components/indexadmin'
// import indexUser from '../components/indexUser'
// // import adduser from '../components/adduser'
// // import Home from '../components/homepage';
// import map from '../components/mappage';
// import login from '../components/login';
// import register from '../components/register';
// import adduser from '../components/adduser'
// import overall from '../components/overall'
// import detailDeviceUser from '../components/userdevicedetail';
// import detaildevice from '../components/detaildevice'
// import detailnamebox from '../components/detailnamebox'
// import detailhardware from '../components/detailhardware'
// import offlineDevice from '../components/offlinedevice'
// import area from '../components/area'
// import forgotpassword from '../components/resetpassword'
// import addpassword from '../components/addnewpassword'
// import testmap from '../components/testmap'
const routes = [

  {
    path: '/',
    name: 'Home',
    component: login
  },
    {
    path: '/login',
    name: 'Login',
    component: login
  },
  //supperadmin
  {
    path: '/supperadmin/adduser',
    name: 'sup_admin_adduser',
    component: sup_admin_adduser
  },
  {
    path: '/supperadmin/area',
    name: 'sup_admin_area',
    component: sup_admin_area
  },
  {
    path: '/supperadmin/map',
    name: 'sup_admin_map',
    component: sup_admin_map
  },
  {
    path: '/supperadmin/detaildevice',
    name: 'sup_admin_detaildevice',
    component: sup_admin_detaildevice
  },
  {
    path: '/supperadmin/detailhardware',
    name: 'sup_admin_detailhardware',
    component: sup_admin_detailhardware
  },
  {
    path: '/supperadmin/detailnamebox',
    name: 'sup_admin_detailnamebox',
    component: sup_admin_detailnamebox
  },
  {
    path: '/supperadmin/overall',
    name: 'sup_admin_overall',
    component: sup_admin_overall
  },
  {
    path: '/supperadmin/landing',
    name: 'sup_admin_landing_overall',
    component: sup_admin_landing_overall
  },
  {
    path: '/supperadmin/statusdevice',
    name: 'supperadminstatusdevice',
    component: sup_admin_statusdevice
  },

  // admin zone
  {
    path: '/zoneadmin/adduser',
    name: 'zone_admin_adduser',
    component: zone_admin_adduser
  },
  {
    path: '/zoneadmin/map',
    name: 'zone_admin_map',
    component: zone_admin_map
  },
  {
    path: '/zoneadmin/detaildevice',
    name: 'zone_admin_detaildevice',
    component: zone_admin_detaildevice
  },
  {
    path: '/zoneadmin/detailhardware',
    name: 'zone_admin_detailhardware',
    component: zone_admin_detailhardware
  },
  {
    path: '/zoneadmin/detailnamebox',
    name: 'zone_admin_detailnamebox',
    component: zone_admin_detailnamebox
  },
  {
    path: '/zoneadmin/overall',
    name: 'zone_admin_overall',
    component: zone_admin_overall
  },
  {
    path: '/zoneadmin/landing',
    name: 'zone_admin_landing_overall',
    component: zone_admin_landing_overall
  },
  {
    path: '/zoneadmin/statusdevice',
    name: 'zoneadminstatusdevice',
    component: zone_admin_statusdevice
  },

  // user zone

  {
    path: '/user/map',
    name: 'usermap',
    component: usermap
  },
  {
    path: '/user/overall',
    name: 'useroverall',
    component: useroverall
  },




  // {
  //   path: '/zoneadmin/adduser',
  //   name: '/zoneperadmin/adduser',
  //   component: login
  // },










  // {
  //   path: '/testmap',
  //   name: 'testmap',
  //   component: testmap
  // },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: login
  // },
  // {
  //   path: '/forgotpassword',
  //   name: 'forgotpassword',
  //   component: forgotpassword
  // },
  // {
  //   path: '/resetpassword',
  //   name: 'resetpassword',
  //   component: addpassword
  // },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: register
  // },
  // {
  //   path: '/user/index',
  //   name: 'userindex',
  //   component: indexUser
  // },
  // {
  //   path: '/user/index',
  //   name: 'userindex',
  //   component: indexUser
  // },
  // {
  //   path: '/user/detaildevice',
  //   name: 'detaildeviceuser',
  //   component: detailDeviceUser
  // },

  // {
  //   path: '/supperadmin/index',
  //   name: 'adminindex',
  //   component: indexAdmin
  // },
  // {
  //   path: '/supperadmin/map',
  //   name: 'map',
  //   component: map
  // },
  // {
  //   path: '/supperadmin/adduser',
  //   name: 'adduser',
  //   component: adduser
  // },
  // {
  //   path: '/supperadmin/area',
  //   name: 'area',
  //   component: area
  // },
  // {
  //   path: '/supperadmin/detailhardware',
  //   name: 'hardware',
  //   component: detailhardware
  // },
  // {
  //   path: '/overall',
  //   name: 'overall',
  //   component: overall
  // },
  // {
  //   path: '/supperadmin/detaildevice',
  //   name: 'detaildevice',
  //   component: detaildevice
  // },
  // {
  //   path: '/supperadmin/detailnamebox',
  //   name: 'detailnamebox',
  //   component: detailnamebox
  // },
  // {
  //   path: '/offline',
  //   name: 'offline',
  //   component: offlineDevice
  // },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router