<template>



    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/supperadmin/landing">
        <img src="../../assets/logo.png" style="width: 250px;" alt="">
      </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <router-link to="/supperadmin/map" style="text-decoration: none;">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="#">อุปกรณ์บนแผนที่</a>
                        </li>
                    </router-link>
                    <router-link to="/supperadmin/area" style="text-decoration: none;">
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#">เขตพื้นที่</a>
                        </li>
                    </router-link>
                    <router-link to="/supperadmin/adduser" style="text-decoration: none;">
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#">ผู้ใช้งาน</a>
                        </li>
                    </router-link>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            อุปกรณ์
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li>
                                <router-link to="/supperadmin/detailnamebox" style="text-decoration: none;"><a
                                        class="dropdown-item" href="">รายละเอียดกล่อง</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/supperadmin/detaildevice" style="text-decoration: none;"><a
                                        class="dropdown-item" href="">รายละเอียดที่ตั้งอุปกรณ์</a></router-link>
                            </li>
                            <li>
                                <router-link to="/supperadmin/detailhardware" style="text-decoration: none;"><a
                                        class="dropdown-item" href="#">รายละเอียดรุ่นอุปกรณ์</a></router-link>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li>
                <router-link to="/supperadmin/statusdevice" style="text-decoration: none;"><a class="dropdown-item  text-warning"
                    href="#">รายงานอุปกรณ์ออฟไลน์</a></router-link>
              </li>
                        </ul>
                    </li>
                </ul>
                <!-- <form class="d-flex">
                    <div class="col-12  text-end">
                        <button class="btn btn-outline-warning ms-2" type="button" data-bs-toggle="modal"
                            data-bs-target="#adduserModal" @click="timeOut()">+ สร้างเขตพื้นที่</button>
                    </div>

                </form> -->
            </div>
        </div>
    </nav>

    <body>
   <div class="col-12 mx-2">
    <div class="h5 mt-3">รายงานอุปกรณ์ออฟไลน์</div>
    <table class="mt-3">
                <tr>
                    <th style="min-width: 180px;">ชื่ออุปกรณ์ภายในกล่อง</th>
                    <th style="min-width: 180px;">ชื่อกล่อง</th>
                    <th style="min-width: 120px;">สถานะ</th>
                    <th style="min-width: 120px;">จังหวัด</th>
                    <!-- <th style="min-width: 120px;">รหัสไปรษณีย์</th> -->
                    <th style="min-width: 100px;">สถานะ</th>
                    <th style="min-width: 100px;">ประเภท</th>
                    <!-- <th style="min-width: 100px;">Live URL</th> -->
                    <!-- <th style="min-width: 100px;">ตำแหน่งที่ตั้ง</th> -->
                    <!-- <th class="text-center" style="min-width: 100px;">แก้ไข</th> -->
                </tr>
                <!-- npk{{ ListDeviceAllItem }} -->

             

            </table>
   </div>
       

    </body>

</template>
<script>

    export default {


        data() {
            return {
              
            };
        },
        methods: {
          
} 
        ,
        created() {
            // checktokenAPI.check();
            // this.getAreaAll();
            // this.gettestBody();
            // this.myFunction();
        }
    };
</script>

<style>
    .popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255, 255, 255, 0);
        padding: 20px;
        /* border: 1px solid black; */
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #dddddd;
    }
</style>